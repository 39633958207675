import React, {useEffect, useState} from 'react';
import {Drawer} from '@mui/material';
import mapT from '../../../assets/images/icon/t_map.png';
import mapNaver from '../../../assets/images/icon/naver_map.png';
import mapKakao from '../../../assets/images/icon/kakao_map.png';
import mapApple from '../../../assets/images/icon/apple_map.png';
import styles from './SearchWayDrawer.module.scss';
import getUserAgent from '../../../utils/UserAgent';

function SearchWayDrawer(props) {
    const {open, onClose, pinData} = props;
    const [isAndroid, setIsAndroid] = useState(false);

    useEffect(() => {
        setIsAndroid(getUserAgent() === 'android');
    }, []);
    
    const onClickDirectionMap = (type) => () => {
        if (pinData !== undefined) {
            window.ReactNativeWebView?.postMessage(
                JSON.stringify({
                    type: 'openMap',
                    pinData: pinData,
                    mapType: type
                })
            );
        }
    };


    return (
        <Drawer anchor="bottom" open={open} onClose={onClose} className={styles.searchWayDrawer}>
            <div className={styles.searchWayCon}>
                <ul>
                    <li onClick={onClickDirectionMap('tmap')}>
                        <img src={mapT} alt="TMAP" />
                        <span>TMAP</span>
                    </li>
                    <li onClick={onClickDirectionMap('naver')}>
                        <img src={mapNaver} alt="네이버 지도" />
                        <span>네이버 지도</span>
                    </li>
                    <li onClick={onClickDirectionMap('kakao')}>
                        <img src={mapKakao} alt="카카오맵" />
                        <span>카카오맵</span>
                    </li>

                    {!isAndroid && (
                        <li onClick={onClickDirectionMap('apple')}>
                            <img src={mapApple} alt="애플 지도" />
                            <span>애플 지도</span>
                        </li>
                    )}
                </ul>
            </div>
        </Drawer>
    );
}

export default SearchWayDrawer;
