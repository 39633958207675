import React, {useState, useEffect} from 'react';

const CustomCheckbox = ({label, checked, onChange, isAll, setAll, checkStyle}) => {
    const [isChecked, setIsChecked] = useState(checked);

    useEffect(() => {
        setIsChecked(checked);
    }, [checked]);

    const handleCheckboxChange = () => {
        const newChecked = !isChecked;
        setIsChecked(newChecked);

        if (isAll) {
            // 전체 동의 체크박스의 상태를 변경
            setAll(newChecked);
        } else {
            // 일반 체크박스의 상태를 변경
            if (onChange) {
                onChange(newChecked);
            }
        }
    };

    return (
        <label className={`checkType01 ${checkStyle ? 'circle' : ''}`}>
            <input type="checkbox" checked={isChecked} onChange={handleCheckboxChange} />
            <i className="icon"></i>
            {label}
        </label>
    );
};

export default CustomCheckbox;
