import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {setLocDrawerOpen} from '../../_store/makePin.slice';

function LocationSearchResult(props) {
    const {resetSearch} = props;

    const dispatch = useDispatch();
    const {pinList} = useSelector((state) => state.makePin);
    const {schKeyword, schList} = useSelector((state) => state.search);

    const [addrList, setAddrList] = useState([]);

    let newRoadAddr = null;
    let jibunAddr = null;

    // 지도에서 위치설정하기 드로어 오픈
    const settingOpen = (lat, lng) => {
        // 핀 목록 추가 30개 제한
        if (pinList.length >= 30) {
            window.alert('핀 목록은 30개를 초과하여 등록할 수 없습니다.');
            return;
        }

        dispatch(setLocDrawerOpen({open: true, lat: lat, lng: lng}));

        setTimeout(() => {
            resetSearch();
        }, 300);
    };

    // 리버스 지오코딩 요청 함수
    function loadGetLonLatFromAddress(lat, lng) {
        //  TData 객체 생성
        const tData = new window.Tmapv3.extension.TData();

        const optionObj = {
            coordType: 'WGS84GEO', // 응답좌표 타입 옵션 설정 입니다.
            addressType: 'A10' // 주소타입 옵션 설정 입니다.
        };

        const params = {
            onComplete: (response) => {
                loadGetonComplete(response, lat, lng);
            }, // 데이터 로드가 성공적으로 완료
            onProgress: loadGetonProgress, // 데이터 로드 중
            onError: loadGetonError // 데이터 로드가 실패
        };

        //  TData 객체의 리버스지오코딩 함수
        tData.getAddressFromGeoJson(lat, lng, optionObj, params);
    }

    // 리버스 지오코딩
    function loadGetonComplete(response, lat, lng) {
        const responseData = response._responseData;

        // json에서 주소 파싱
        const arrResult = responseData.addressInfo;

        // 법정동 마지막 문자
        const lastLegal = arrResult.legalDong?.charAt(arrResult.legalDong.length - 1);

        // 새주소
        newRoadAddr = arrResult.city_do + ' ' + arrResult.gu_gun + ' ';

        if (arrResult.eup_myun === '' && (lastLegal === '읍' || lastLegal === '면')) {
            // 읍면
            newRoadAddr += arrResult.legalDong;
        } else {
            newRoadAddr += arrResult.eup_myun;
        }
        newRoadAddr += ' ' + arrResult.roadName + ' ' + arrResult.buildingIndex;

        // 구주소
        jibunAddr =
            arrResult.city_do +
            ' ' +
            arrResult.gu_gun +
            ' ' +
            arrResult.legalDong +
            ' ' +
            arrResult.ri +
            ' ' +
            arrResult.bunji;

        return jibunAddr;
    }

    // 리버스 지오코딩 데이터 로드중
    function loadGetonProgress() {}

    // 리버스 지오코딩 데이터 로드 중 에러
    function loadGetonError() {}

    // const jibunAddress = (lat, lng) => {
    //     loadGetLonLatFromAddress(lat, lng);
    // };

    useEffect(() => {
        const placeList = schList.map((item) => ({
            roadAddress:
                item.collectionType === 'poi'
                    ? item.newAddressList.newAddress[0]?.fullAddressRoad + ' ' + item.name
                    : item.newAddressList.newAddress[0]?.fullAddressRoad,
            // jibunAddress: '',
            lat: item.newAddressList.newAddress[0]?.centerLat,
            lng: item.newAddressList.newAddress[0]?.centerLon
        }));
        setAddrList(placeList);
    }, [schList]);

    return (
        <div className="schLocListWrap">
            <div className="schWord">
                <b>‘{schKeyword}’</b> 에 대한 검색 결과입니다.
            </div>
            <ul className="schLocList">
                {addrList.map((item, idx) => (
                    <li key={idx} onClick={() => settingOpen(item.lat, item.lng)}>
                        <p>{item.roadAddress}</p>
                        {/* <span>[지번] {item.jibunAddress}</span> */}
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default LocationSearchResult;
