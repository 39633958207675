import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {stackRouterNavigate, stackRouterPush, stackRouterReset} from '../../_helpers/StackRouter';
import {setMakePinOpen} from '../../_store/makePin.slice';
import MakePin from '../../pages/MakePin/MakePin';
import {localStorageUtil} from '../../utils/LocalStorage';
import Icon from '../Icon/Icon';
import styles from './Navigation.module.scss';

function Navigation(props) {
    const {activeNav} = props;

    const dispatch = useDispatch();
    const {makePinOpen} = useSelector((state) => state.makePin);

    const memberId = localStorageUtil.get('memberId');

    // 내 핀 만들기 모달 오픈
    const handleMakePinOpen = () => {
        dispatch(setMakePinOpen(true));
    };

    // 내 핀 만들기 취소
    const handleMakePinClose = () => {
        dispatch(setMakePinOpen(false));
    };

    const commonNavList = [
        {
            // pathname: '/',
            classname: 'home',
            icon: 'homeLine',
            activeIcon: 'home',
            title: '홈',
            onClick: () => stackRouterReset('Home', '/')
        },
        {
            // pathname: '/search',
            classname: 'search',
            icon: 'search',
            activeIcon: 'search',
            title: '검색',
            onClick: () => stackRouterNavigate('Search', '/search')
        },
        {
            // pathname: '/customPin',
            classname: 'customPin',
            icon: 'customPin',
            activeIcon: 'customPin',
            title: '커스텀핀',
            onClick: () => stackRouterPush('Setting', '/customPin')
        },
        {icon: 'rectWriteS', classname: 'makePin', title: '내 핀 만들기', onClick: () => handleMakePinOpen()},
        {
            // pathname: `/feed/index?memberId=${memberId}&pageType=myPage`,
            classname: 'myPage',
            icon: 'myPageLine',
            activeIcon: 'myPage',
            title: '내 피드',
            onClick: () =>
                stackRouterNavigate('MyFeedIndex', `/feed/index?memberId=${memberId}&pageType=myPage`)
        }
    ];

    return (
        <>
            <nav id={styles.Navigation}>
                {commonNavList.map((list, idx) => {
                    const isActive = list.activeIcon === activeNav;

                    const name = isActive ? list.activeIcon : list.icon;
                    const fill = isActive ? '#222' : '#a9a9a9';

                    return (
                        <button type="button" key={idx} onClick={list.onClick}>
                            <Icon
                                name={name}
                                width={list.icon === 'search' ? '20' : '25'}
                                height={list.icon === 'search' ? '20' : '24'}
                                fill={fill}
                            />
                            <span>{list.title}</span>
                        </button>
                    );
                })}
            </nav>

            {/* 내 핀 만들기 */}
            <MakePin makePinOpen={makePinOpen} makePinClose={handleMakePinClose} />
        </>
    );
}

export default Navigation;
