import React, {useEffect, useState} from 'react';
import {Drawer} from '@mui/material';
import IconButton from '../../Button/IconButton/IconButton';
import {LoadingButton} from '@mui/lab';
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';
import SwipeMap from '../../Map/SwipeMap/SwipeMap';
import BasicMap from '../../Map/BasicMap';
import './SetLocationDrawer.scss';
import UploadImg from '../../Input/UploadImg/UploadImg';
import TextInput from '../../Input/TextInput/TextInput';
import {useSelector, useDispatch} from 'react-redux';
import {setPinList, setUpdatePinList, setMakePinStep, setResetPlaceInfo} from '../../../_store/makePin.slice';

function SetLocationDrawer(props) {
    const {isOpen, onClose} = props;

    const dispatch = useDispatch();
    const {pinList, placeInfo, makePinMode, locDrawerOpen} = useSelector((state) => state.makePin);
    const {mapDragOn} = useSelector((state) => state.makePin);

    const [loading, setLoading] = useState(false);
    const [placeNameValue, SetPlaceNameValue] = useState('');
    const [addrDetailValue, SetAddrDetailValue] = useState('');
    const [visibleForm, setVisibleForm] = useState(false);
    const [imgStringList, setImgStringList] = useState([]);

    // 이 위치로 설정하기 버튼
    const handleSetLocation = () => {
        setVisibleForm(true);
    };

    // 장소 이름 input
    const handlePlaceNameChange = (value) => {
        SetPlaceNameValue(value);
    };

    // 상세주소 입력 input
    const handleAddressChange = (value) => {
        SetAddrDetailValue(value);
    };

    // 지도에서 위치 설정하기 드로어 닫고 드로어 상태 초기화
    const resetDrawer = () => {
        onClose();
        SetPlaceNameValue('');
        SetAddrDetailValue('');
        setImgStringList([]);
        setVisibleForm(false);
        dispatch(setResetPlaceInfo());
        dispatch(setMakePinStep({current: 'makePinIndex', prev: 'makePinChoice'}));
    };

    // 핀-하기 (장소 추가)
    const handleAddPinList = () => {
        if (makePinMode.mode === 'modify') {
            // 게시글 수정
            const updatePinList = [...pinList];
            updatePinList[makePinMode.index] = {
                ...updatePinList[makePinMode.index],
                addr: placeInfo.addr,
                addrDetail: addrDetailValue,
                addrStreet: placeInfo.addrStreet,
                imgStringList: imgStringList,
                lat: placeInfo.lat,
                lng: placeInfo.lng,
                name: placeNameValue
            };
            dispatch(setUpdatePinList(updatePinList));
        } else {
            // 게시글 작성
            const newPin = {
                addr: placeInfo.addr,
                addrDetail: addrDetailValue,
                addrStreet: placeInfo.addrStreet,
                imgStringList: imgStringList,
                isMain: pinList.length < 1, // pinList 첫번째 등록이면 대표로 우선 등록되도록
                lat: placeInfo.lat,
                lng: placeInfo.lng,
                name: placeNameValue,
                uiSeq: 0
            };
            dispatch(setPinList(newPin));
        }
        resetDrawer();
    };

    useEffect(() => {
        if (locDrawerOpen.open) {
            SetPlaceNameValue(makePinMode.mode === 'modify' ? placeInfo.name : '');
            SetAddrDetailValue(makePinMode.mode === 'modify' ? placeInfo.addrDetail : '');
            setImgStringList(makePinMode.mode === 'modify' ? placeInfo.imgStringList : []);
        } else {
            SetPlaceNameValue('');
            SetAddrDetailValue('');
            setImgStringList([]);
        }
    }, [locDrawerOpen.open]);

    return (
        <Drawer anchor="bottom" open={isOpen} className="setLocationDrawer">
            <div className="drawerWrap">
                <div className="drawerTitleArea">
                    <h3 className="title">지도에서 위치 설정하기</h3>
                    <IconButton name="close" width="24" height="24" onClick={resetDrawer} />
                </div>

                {!visibleForm ? (
                    <>
                        {/* 이 위치로 설정하기 */}
                        <div className="drawerContents">
                            {/* 스와이프 지도 */}
                            {locDrawerOpen.open && <SwipeMap />}

                            <div className="section">
                                <div className="addrArea">
                                    {mapDragOn ? (
                                        <p className="loading">위치 변경중..</p>
                                    ) : (
                                        <>
                                            <p className="roadAddr">
                                                {placeInfo.addr
                                                    ? placeInfo.addr
                                                    : '도로명 주소를 지원하지 않습니다.'}
                                            </p>
                                            <span className="lotAddr">[지번] {placeInfo.addrStreet}</span>
                                        </>
                                    )}
                                </div>

                                <div className="fixedBottomBtnArea">
                                    <LoadingButton
                                        variant="contained"
                                        fullWidth={true}
                                        size="large"
                                        loading={loading} // 로딩중 유무
                                        loadingPosition="start"
                                        startIcon={<LoginOutlinedIcon />}
                                        onClick={handleSetLocation}
                                        disabled={mapDragOn}
                                    >
                                        이 위치로 설정하기
                                    </LoadingButton>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        {/* 핀-하기 */}
                        <div className="drawerContents">
                            <BasicMap />
                            <div className="section">
                                <div className="addrArea">
                                    <p className="roadAddr">
                                        {placeInfo.addr ? placeInfo.addr : '도로명 주소를 지원하지 않습니다.'}
                                    </p>
                                    <span className="lotAddr">[지번]{placeInfo.addrStreet}</span>
                                </div>
                                <div className="fieldArea">
                                    <TextInput
                                        size="small"
                                        value={placeNameValue}
                                        placeholder="장소 이름(필수사항)"
                                        onInputChange={handlePlaceNameChange}
                                    />
                                    <TextInput
                                        size="small"
                                        value={addrDetailValue}
                                        placeholder="상세주소 입력"
                                        onInputChange={handleAddressChange}
                                    />
                                </div>

                                {/* 이미지 업로드 */}
                                <UploadImg
                                    imgStringList={imgStringList}
                                    setImgStringList={setImgStringList}
                                />

                                <div className="fixedBottomBtnArea">
                                    <LoadingButton
                                        variant="contained"
                                        fullWidth={true}
                                        size="large"
                                        loading={loading} // 로딩중 유무
                                        loadingPosition="start"
                                        startIcon={<LoginOutlinedIcon />}
                                        disabled={!placeNameValue}
                                        onClick={handleAddPinList}
                                    >
                                        핀-하기
                                    </LoadingButton>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </Drawer>
    );
}

export default SetLocationDrawer;
