import React, {useEffect, useState, useRef} from 'react';
import './TmapTestPage.scss';

function TmapTestPage() {
    const [resultText, setResultText] = useState('');
    const myElementRef = useRef(null);

    useEffect(() => {
        if (myElementRef.current === null) {
            const script = document.createElement('script');
            script.innerHTML = `
                var myMapCenterPosition = null;    
                function initTmap(){
                // map 생성
                // Tmapv3.Map을 이용하여, 지도가 들어갈 div, 넓이, 높이를 설정합니다.
                var map = new Tmapv3.Map("map_div", { // 지도가 생성될 div
                    center : new Tmapv3.LatLng(37.563545, 126.9752809),
                    width : "100%",	// 지도의 넓이
                    height : "400px",	// 지도의 높이
                    zoom : 16	// 지도 줌레벨
                });
            
                map.on("DragEnd", function(evt) {
                    myMapCenterPosition = evt._target._transform._center;
                });
                }
            
                initTmap();
            `;

            script.type = 'text/javascript';
            script.id = 'tmap';
            script.async = 'async';
            document.head.appendChild(script);
            myElementRef.current = script;
        }

        return () => {
            if (myElementRef.current.parentNode === document.head) {
                document.head.removeChild(myElementRef.current);
            }
        };
    }, []);

    const onClickSelect = () => {
        const centerTemp = window.myMapCenterPosition;
        if (centerTemp === null) {
            setResultText('lat: 37.563545, lng: 126.9752809');
        } else {
            setResultText(`lat: ${centerTemp._lat}, lng: ${centerTemp._lng}`);
        }
    };

    return (
        <div className="tmapTestPage" id="mainApp">
            <div className="mapArea">
                <div className="centerPosText">@</div>
                <div className="tmap" id="map_div"></div>
            </div>

            <div className="buttonArea">
                <button className="selectBtn" onClick={onClickSelect}>
                    선택
                </button>
                <div>{resultText}</div>
            </div>
        </div>
    );
}

export default TmapTestPage;
