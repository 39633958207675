import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Dialog} from '@mui/material';
import PrivacyPolicy from '../../Agreement/Member/PrivacyPolicy';
import ServiceAgreement from '../../Agreement/Member/ServiceAgreement';
import Icon from '../../Icon/Icon';

function PolicyLayer(props) {
    const {open, handleClose, title, agreeType} = props;

    const scrollRef = useRef(null);
    const [isScroll, setIsScroll] = useState(false);

    const onScroll = useCallback((evt) => {
        const div = evt.target;

        const currentScrollPosition = div.scrollTop;

        if (currentScrollPosition > 20) {
            setIsScroll(true);
        } else {
            setIsScroll(false);
        }
    }, []);

    const layerClose = () => {
        handleClose();
    };

    useEffect(() => {
        if (open) {
            setIsScroll(false);
        }
    }, [open]);

    return (
        <>
            <Dialog fullScreen open={open} onClose={handleClose} className="fullPageModal">
                <div className="layerPop" ref={scrollRef} onScroll={onScroll}>
                    <div className={`layerHeader ${isScroll ? 'scroll' : ''}`}>
                        <strong className="title">{title}</strong>
                        <button type="button" className="btnClose" onClick={layerClose}>
                            <Icon width="24" height="24" viewBox="0 0 24 24" name="close_b" />
                        </button>
                    </div>
                    <div className="layerContents">
                        <div id="agreeContents" className="inner">
                            <div className="agreeCon">
                                {agreeType ? (
                                    <>
                                        {/****** 회원가입 이용 약관 ******/}
                                        {agreeType === 'JOIN_PRIVACY' && <PrivacyPolicy />}
                                        {agreeType === 'JOIN_SERVICE' && <ServiceAgreement />}
                                    </>
                                ) : (
                                    '페이지를 찾을 수 없습니다.'
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        </>
    );
}

export default PolicyLayer;
