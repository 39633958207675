import React, {forwardRef} from 'react';
import {Modal} from '@mui/material';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Pagination} from 'swiper/modules';
import 'swiper/css';
import './PhotoModal.scss';
import Icon from '../../Icon/Icon';

const PhotoModal = forwardRef((props, ref) => {
    const {open, onClose, data} = props;

    return (
        <Modal open={open} keepMounted={true} className="photoModal">
            <div className="modalWrap">
                <button type="button" className="btnClose" onClick={onClose}>
                    <Icon name="close" width="24" height="24" fill="#fff" />
                </button>
                <div className="sliderWrap">
                    <Swiper
                        modules={[Pagination]}
                        pagination={{
                            el: '.photo-pagination',
                            clickable: true
                        }}
                        ref={ref}
                    >
                        {data.map((list) => {
                            return (
                                <SwiperSlide key={list.id}>
                                    <img src={list.imgUrl} alt="" />
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                </div>
                <div className="photo-pagination"></div>
            </div>
        </Modal>
    );
});

PhotoModal.displayName = 'PhotoModal';

export default PhotoModal;
