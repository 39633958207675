import React from 'react';
import styles from './UserIdList.module.scss';
import SchProfileArea from '../../Profile/SchProfileArea';
import ProfileArea from '../../Profile/ProfileArea';

function UserIdList(props) {
    const {schRealTime, feedDTO, searchValue} = props;

    return (
        <div className={styles.searchIdListWrap}>
            <ul className={styles.userIdList}>
                {schRealTime ? (
                    props.feedDTO.map((item, idx) => (
                        <li key={idx}>
                            <SchProfileArea profile={item} searchValue={searchValue}/>
                        </li>
                    ))
                ) : (
                    feedDTO?.map((list) => {
                        return (
                            <li key={list.memberId}>
                                <ProfileArea feedDTO={list} hideBtn={true} />
                            </li>
                        );
                    })
                )}
            </ul>
        </div>
    );
}

export default UserIdList;
