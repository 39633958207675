import React, {useEffect, useState, forwardRef} from 'react';
import Icon from '../../Icon/Icon';
import styles from './SearchInput.module.scss';

const SearchInput = forwardRef((props, ref) => {
    const {onInputChange, pressEnterKey, placeholder, value, handleOnFocus} = props;

    const [inputValue, setInputValue] = useState(props.value ? props.value : '');
    const [isFocused, setIsFocused] = useState(false);

    const onChangeSearchValue = (event) => {
        const value = event.target.value;
        setInputValue(value);
        onInputChange(value);
    };

    const handleDeleteClick = () => {
        setInputValue('');
        onInputChange('');
    };

    const handleInputBlur = () => {
        // 포커스를 잃었을 때 일정 지연 후에 포커스 상태 업데이트
        setTimeout(() => {
            setIsFocused(false);
        }, 100);
    };

    const handleInputFocus = () => {
        setIsFocused(true);

        if (handleOnFocus) {
            handleOnFocus();
        }
    };

    useEffect(() => {
        if (value === '') {
            handleDeleteClick();
        }
        setInputValue(value ?? '');
    }, [value]);

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            pressEnterKey(event.target.value);
        }
    };

    return (
        <div className={styles.searchInputArea}>
            <Icon name="search_thin" width="20" height="20" fill="#777" className={styles.searchIcon} />
            <input
                type="text"
                value={inputValue}
                placeholder={placeholder}
                onBlur={handleInputBlur}
                onFocus={handleInputFocus}
                onChange={onChangeSearchValue}
                onKeyDown={handleKeyDown}
                ref={ref}
            />

            {/* 텍스트 삭제 버튼 */}
            {isFocused && inputValue && (
                <button type="button" className={styles.btnTxtDelete} onClick={handleDeleteClick}>
                    <Icon name="delete" width="17" height="17" fill="#bbb" />
                </button>
            )}
        </div>
    );
});

SearchInput.displayName = 'SearchInput';

export default SearchInput;
