import React from 'react';

function LocationSearchGuide(props) {
    return (
        <div className="section">
            <h3>이렇게 검색해보세요</h3>
            <div className="searchGuide">
                <dl>
                    <dt>도로명 + 건물번호</dt>
                    <dd>예) 동작구 동작대로 88</dd>
                </dl>
                <dl>
                    <dt>지역명(동/리) + 번지</dt>
                    <dd>예) 사당동 1019</dd>
                </dl>
                <dl>
                    <dt>지역명(동/리) + 건물명</dt>
                    <dd>예) 사당동 위핀타워</dd>
                </dl>
            </div>
        </div>
    );
}

export default LocationSearchGuide;
