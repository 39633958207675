import React, {useRef} from 'react';

import Navigation from '../Navigation/Navigation';
import Header from './Header/Header';

function Layout(props) {
    const {main, pageTitle, back, isMine, children, nav, activeNav} = props;

    return (
        <div id="contents">
            <Header main={main} pageTitle={pageTitle} back={back} isMine={isMine} />

            <div className="contentsArea">{children}</div>

            {nav !== false && <Navigation activeNav={activeNav} />}
        </div>
    );
}

export default Layout;
