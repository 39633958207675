import React, {useState} from 'react';
import Icon from '../../Icon/Icon';
import styles from './BlackList.module.scss';
import ReportDetailModal from '../../Modal/ReportDetailModal/ReportDetailModal';

function BlackListItem(props) {
    const {item, tabValue, getRequestList, getBlackList} = props;

    const [detailModalOpen, setDetailModalOpen] = useState(false);

    const settingIcon = (
        <Icon name="arr_right" width="14" height="14" fill="#A7A7A7" className={styles.iconArr} />
    );

    const handleDetailModalOpen = () => {
        setDetailModalOpen(true);
    };

    return (
        <>
            <li onClick={handleDetailModalOpen}>
                <button type="button">
                    <div className={styles.textArea}>
                        <strong className={styles.title}>{item.blackNick}</strong>
                        <span>{item.createdAt}</span>
                    </div>
                    {settingIcon}
                </button>
            </li>
            <ReportDetailModal
                data={item}
                tabValue={tabValue}
                open={detailModalOpen}
                setOpen={setDetailModalOpen}
                getRequestList={getRequestList}
                getBlackList={getBlackList}
            />
        </>
    );
}

export default BlackListItem;
