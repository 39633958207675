import React, {useState, useRef, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Button from '@mui/material/Button';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import IconButton from '../../Button/IconButton/IconButton';
import {styled} from '@mui/material/styles';
import styles from './UploadImg.module.scss';
import getUserAgent from '../../../utils/UserAgent';
import {setWhereCallFrom} from '../../../_store/permission.slice';

let myImgUploadRef = null;
export const openImgLoadWindowUploadImg = () => {
    myImgUploadRef.current.click();
};

function UploadImg(props) {
    const {imgStringList, setImgStringList} = props;

    const dispatch = useDispatch();
    const imgUploadRef = useRef(null);
    myImgUploadRef = imgUploadRef;

    const ua = getUserAgent();

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1
    });

    // 이미지를 base64로 변환하는 함수
    function convertImageToBase64(file) {
        const reader = new FileReader();

        reader.onloadend = () => {
            const base64Data = reader.result;
            // 이미지가 변환될 때마다 기존 imgStringList에 추가
            setImgStringList((prevImgStringList) => [...prevImgStringList, base64Data]);
        };

        reader.readAsDataURL(file);
    }

    // 이미지가 선택되었을 때 호출되는 이벤트 핸들러
    const handleImageUpload = (event) => {
        const selectedFiles = event.target.files;

        if (selectedFiles.length > 0) {
            // 이미지 업로드시 이미지 첨부 10장까지 제한
            if (imgStringList.length + selectedFiles.length > 10) {
                window.alert('이미지는 10장까지 첨부 가능합니다.');
                return;
            }

            // 선택된 파일들을 base64로 변환하여 imgStringList에 추가
            for (const file of selectedFiles) {
                convertImageToBase64(file);
            }
        }
    };

    // 이미지 삭제
    const handleImageDelete = (index) => {
        const newStringImages = [...imgStringList];
        newStringImages.splice(index, 1);
        setImgStringList(newStringImages);
    };

    // 카메라 접근 권한 허용 여부 확인
    const photoPermissionCheck = () => {
        // 버튼 클릭시 이미지 첨부 10장까지 제한
        if (imgStringList.length >= 10) {
            window.alert('이미지는 10장까지 첨부 가능합니다.');
            return;
        }

        if (ua !== 'web') {
            dispatch(setWhereCallFrom('UploadImg'));
            window.ReactNativeWebView?.postMessage(
                JSON.stringify({
                    type: 'checkPhotoCameraPermission'
                })
            );
        } else {
            imgUploadRef.current.click();
        }
    };

    return (
        <div className={styles.uploadImgArea}>
            <div className={styles.btnImgUpload}>
                <Button
                    component="label"
                    variant="outlined"
                    color="gray"
                    startIcon={<CameraAltIcon />}
                    className={styles.btnAddImg}
                    onClick={photoPermissionCheck}
                >
                    <p className={styles.num}>
                        <b>{imgStringList.length}</b>/10
                    </p>
                </Button>

                <VisuallyHiddenInput
                    ref={imgUploadRef}
                    onChange={handleImageUpload}
                    type="file"
                    accept="image/*"
                    multiple
                />
            </div>

            <ul className={styles.uploadImg}>
                {imgStringList?.map((image, index) => (
                    <li key={index}>
                        <img src={image} alt={`Image ${index}`} width="120" height="120" />
                        <IconButton
                            name="delete_circle"
                            width="16"
                            height="16"
                            fill="#393A3F"
                            className={styles.btnImgDelete}
                            onClick={() => handleImageDelete(index)}
                        />
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default UploadImg;
