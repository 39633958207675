import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {TrendingUpRounded} from '@mui/icons-material';
import Switch from '@mui/material/Switch';

import {setAppNotiSave, setIsPushSetting} from '../../../_store/etc.slice';
import Layout from '../../../components/Layout/Layout';
import axioswrapper from '../../../utils/Axios';
import {localStorageUtil} from '../../../utils/LocalStorage';
import styles from './NotiSetting.module.scss';

function NotiSetting() {
    const dispatch = useDispatch();
    const [switchStates, setSwitchStates] = useState({
        all: false,
        likes: false,
        follows: false,
        posts: false,
        comments: false
    });
    const {appNotiSave, isPushSetting} = useSelector((state) => state.etc);

    // 알림 상태 조회
    const getNotiState = () => {
        axioswrapper
            .Axios('GET', `push/list?memberId=${localStorageUtil.get('memberId')}`)
            .then((response) => {
                console.log('푸시 상태 조회 ', response);
                setSwitchStates({
                    all: response.data?.data.allActivated,
                    likes: response.data?.data.likeActivated,
                    follows: response.data?.data.followActivated,
                    posts: response.data?.data.feedActivated,
                    comments: response.data?.data.replyActivated
                });
            })
            .catch((error) => {
                console.log('푸시 상태 조회 실패', error);
            });
    };

    const saveNotiState = () => {
        const payload = {
            memberId: localStorageUtil.get('memberId'),
            allActivated: switchStates.all,
            feedActivated: switchStates.posts,
            followActivated: switchStates.follows,
            replyActivated: switchStates.comments,
            likeActivated: switchStates.likes
        };
        axioswrapper
            .Axios('POST', 'push/update', payload)
            .then((response) => {
                console.log('push/update 성공', response);
            })
            .catch((error) => {
                console.log('push/update error', error);
            });
    };

    // 상태 저장 로직 전용 useEffect
    useEffect(() => {
        if (appNotiSave) {
            saveNotiState();
            dispatch(setAppNotiSave(false));
        }
    }, [appNotiSave]);

    // 상태 조회 로직 전용 useEffect
    useEffect(() => {
        // redux isPushSetting 초기화
        if (isPushSetting) {
            dispatch(setIsPushSetting(false));
        }

        getNotiState();
    }, []); // 의존성 배열을 비워 컴포넌트 마운트 시에만 실행

    // 전체 알림 스위치 상태 변경
    const handleAllSwitchChange = (event) => {
        const {checked} = event.target;
        setSwitchStates({
            all: checked,
            likes: checked,
            follows: checked,
            posts: checked,
            comments: checked
        });
    };

    // 개별 스위치의 상태를 변경
    const handleSwitchChange = (name) => (event) => {
        const {checked} = event.target;
        const newSwitchStates = {
            ...switchStates,
            [name]: checked
        };

        // 모든 개별 스위치가 활성화되어 있는지 확인
        const allChecked = Object.keys(newSwitchStates)
            .filter((key) => key !== 'all') // 'all' 키는 제외하고 검사
            .every((key) => newSwitchStates[key]);

        setSwitchStates({
            ...newSwitchStates,
            all: allChecked // 모든 스위치가 켜져 있으면 전체 스위치도 켜짐
        });
    };

    return (
        <Layout pageTitle="알림" back={TrendingUpRounded} activeNav="myPage">
            <div className={styles.notiSettingWrap}>
                <div className={styles.notiSetItemAll}>
                    <div className={styles.notiTextWrap}>
                        <span className={styles.title}>전체알림</span>
                        <span className={styles.subtitle}>전체 알림을 수신합니다</span>
                    </div>

                    <Switch
                        checked={switchStates.all}
                        onChange={handleAllSwitchChange}
                        className="customSwitch"
                    />
                </div>

                <div className={styles.notiSetItem}>
                    <div className={styles.notiTextWrap}>
                        <span className={styles.title}>좋아요</span>
                        <span className={styles.subtitle}>내 게시물에 좋아요 알람을 수신</span>
                    </div>

                    <Switch
                        checked={switchStates.likes}
                        onChange={handleSwitchChange('likes')}
                        className="customSwitch"
                    />
                </div>

                <div className={styles.notiSetItem}>
                    <div className={styles.notiTextWrap}>
                        <span className={styles.title}>팔로우</span>
                        <span className={styles.subtitle}>팔로우, 팔로우 취소 알람을 수신</span>
                    </div>

                    <Switch
                        checked={switchStates.follows}
                        onChange={handleSwitchChange('follows')}
                        className="customSwitch"
                    />
                </div>

                <div className={styles.notiSetItem}>
                    <div className={styles.notiTextWrap}>
                        <span className={styles.title}>게시물</span>
                        <span className={styles.subtitle}>팔로잉의 게시물 추가 알림 수신</span>
                    </div>

                    <Switch
                        checked={switchStates.posts}
                        onChange={handleSwitchChange('posts')}
                        className="customSwitch"
                    />
                </div>

                <div className={styles.notiSetItem}>
                    <div className={styles.notiTextWrap}>
                        <span className={styles.title}>댓글</span>
                        <span className={styles.subtitle}>댓글 대댓글 알람을 수신</span>
                    </div>

                    <Switch
                        checked={switchStates.comments}
                        onChange={handleSwitchChange('comments')}
                        className="customSwitch"
                    />
                </div>
            </div>
        </Layout>
    );
}

export default NotiSetting;
