import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {setFeedListByTag, setIsClickBack, setSchTagKeyword} from '../../_store/search.slice';
import SearchInput from '../../components/Input/SearchInput/SearchInput';
import Layout from '../../components/Layout/Layout';
import BestIdList from '../../components/List/BestIdList/BestIdList';
import FeedList from '../../components/List/FeedList/FeedList';
import UserIdList from '../../components/List/UserIdList/UserIdList';
import axioswrapper from '../../utils/Axios';
import {localStorageUtil} from '../../utils/LocalStorage';
import './Search.scss';

function Search(props) {
    const dispatch = useDispatch();
    // 검색
    const [searchValue, setSearchValue] = useState(undefined);
    const [topList, setTopList] = useState([]);
    const [allMemberList, setAllMemberList] = useState([]);
    const [filterList, setFilterList] = useState([]);
    const [feedList, setFeedList] = useState([]);
    const [isSearchResultHash, setIsSearchResultHash] = useState(false);
    const [searchResultCount, setSearchResultCount] = useState('');
    const {isClickBack, feedListByTag, schTagKeyword} = useSelector((state) => state.search);
    const [page, setPage] = useState(0);
    const [size, setSize] = useState(20);
    const [hasNext, setHasNext] = useState(false);

    const handleInputChange = (value) => {
        setSearchValue(value);
        setFilterList(
            allMemberList.filter((item) => {
                return item.nickname.includes(value);
            })
        );
    };

    const handlePressEnter = (value) => {
        getFeedListByTag(value, page, size);
    };

    const getFeedListByTag = (value, pageNum, pageSize) => {
        if (value && value.trim() !== '' && value.startsWith('#')) {
            const pNum = pageNum === undefined ? page : pageNum;
            const pSize = pageSize === undefined ? size : pageSize;
            const tag = value.replace(/#/g, '');
            axioswrapper
                .Axios(
                    'GET',
                    `feed/search?size=` +
                        pSize +
                        `&page=` +
                        pNum +
                        `&memberId=${localStorageUtil.get('memberId')}&tag=` +
                        tag
                )
                .then((response) => {
                    setHasNext(response.data.data.hasNext);
                    setPage(response.data.data.pageNumber);
                    if (response.data.data.pageNumber === 0) {
                        setSearchResultCount(response.data.data.lists.length);
                        setFeedList(response.data.data.lists);
                        dispatch(setFeedListByTag(response.data.data.lists));
                    } else {
                        setSearchResultCount(searchResultCount + response.data.data.lists.length);
                        setFeedList([...feedList, ...response.data.data.lists]);
                        dispatch(setFeedListByTag([...feedList, ...response.data.data.lists]));
                    }

                    setIsSearchResultHash(true);

                    dispatch(setSchTagKeyword(tag));
                })
                .catch((error) => {
                    console.log('feed/search error', error);
                });
        }
    };

    // 스크롤 이벤트 핸들러
    const handleScroll = (event) => {
        const {scrollTop, clientHeight, scrollHeight} = event.currentTarget;
        var scrollSub = scrollHeight - Math.ceil(scrollTop);
        if (Math.abs(scrollSub - clientHeight) <= 1) {
            if (hasNext) {
                const nextPage = page + 1;
                setPage(nextPage);
                getFeedListByTag(searchValue, nextPage, size);
            }
        }
    };

    useEffect(() => {
        if (searchValue === '') {
            setIsSearchResultHash(false);
        }
    }, [searchValue]);

    useEffect(() => {
        if (isClickBack) {
            // console.log('schTagKeyword', schTagKeyword);
            if (schTagKeyword !== '' && schTagKeyword !== null) {
                const newSearchValue = '#' + schTagKeyword;

                setSearchValue(newSearchValue);
                setSearchResultCount(feedListByTag.length);
                setFeedList(feedListByTag);

                setIsSearchResultHash(true);
            }

            dispatch(setIsClickBack(false));
        }
    }, [isClickBack, searchValue]);

    useEffect(() => {
        axioswrapper
            .Axios('GET', `member/top?memberId=${localStorageUtil.get('memberId')}`)
            .then((response) => {
                setTopList(response.data.data);
            })
            .catch((error) => {
                console.log('member/top error', error);
            });

        axioswrapper
            .Axios('GET', `member/all?memberId=${localStorageUtil.get('memberId')}`)
            .then((response) => {
                setAllMemberList(response.data.data.lists);
                setFilterList(response.data.data.lists);
            })
            .catch((error) => {
                console.log('member/all error', error);
            });
    }, []);

    return (
        <Layout pageTitle="검색" activeNav="search">
            <div id="searchPage" className="innerWrap">
                <SearchInput
                    onInputChange={handleInputChange}
                    pressEnterKey={handlePressEnter}
                    placeholder="아이디, #해시태그 로 검색"
                    value={searchValue}
                />

                {!searchValue ? (
                    <>
                        {/* 추천 아이디 리스트 */}
                        <div className="schConArea">
                            <div className="totalCountArea">
                                추천 아이디 <strong>Top 10</strong>
                            </div>
                            <BestIdList topList={topList} />
                        </div>
                    </>
                ) : isSearchResultHash ? (
                    <>
                        {/* 해시태그 검색 결과 */}
                        <div className="schConArea" onScroll={handleScroll}>
                            <div className="totalCountArea">
                                검색결과 <strong>{searchResultCount}</strong>
                            </div>
                            <FeedList feedList={feedList} />
                        </div>
                    </>
                ) : (
                    <>
                        {/* 실시간 검색 */}
                        <div className="schConArea">
                            <UserIdList schRealTime={true} feedDTO={filterList} searchValue={searchValue} />
                        </div>
                    </>
                )}
            </div>
        </Layout>
    );
}

export default Search;
