import React, {useEffect, useState} from 'react';

import Layout from '../../components/Layout/Layout';
import axioswrapper from '../../utils/Axios';
import {localStorageUtil} from '../../utils/LocalStorage';
import styles from './CustomPin.module.scss';
import CustomPinItem from './CustomPinItem';

function CustomPin() {
    const [customPinList, setCustomPinList] = useState([]);
    const [pageNum, setPageNum] = useState(0);
    const [selectedPin, setSelectedPin] = useState({});
    const [usedPin, setUsedPin] = useState({});

    // 커스텀 핀 리스트 조회
    const getCustomPinListData = () => {
        const memberId = localStorageUtil.get('memberId');

        axioswrapper
            .Axios('GET', `custom-pin/list?memberId=${memberId}&page=${pageNum}&size=20`)
            .then((response) => {
                const lists = response.data.data.lists;
                const currentPin = lists.find((pin) => pin.isCurrent);
                setCustomPinList(lists);
                setSelectedPin(currentPin);
                setUsedPin(currentPin);

                if (currentPin) {
                    localStorageUtil.set('mainPinIcon', currentPin.mainPin);
                    localStorageUtil.set('subPinIcon', currentPin.subPin);
                }
            })
            .catch((error) => {
                console.log('custom-pin/list error', error);
            });
    };

    useEffect(() => {
        getCustomPinListData();
    }, [pageNum]);

    // 핀 선택 시 호출되는 함수
    const handleSelectPin = (pin) => {
        setSelectedPin(pin);
        localStorageUtil.set('mainPinIcon', pin.mainPin);
        localStorageUtil.set('subPinIcon', pin.subPin);
    };

    return (
        <Layout pageTitle="내 핀 설정" back={true} activeNav="customPin">
            <div className={styles.customPinWrapper}>
                <div className={styles.selectedPin}>
                    <div className={styles.borderless}>
                        <span>{usedPin?.endAt === null ? '무제한' : usedPin?.endAt}</span>
                    </div>

                    <div className={styles.selectedMarker}>
                        {usedPin?.mainPin && <img src={usedPin?.mainPin} />}
                    </div>

                    <div className={styles.selectedPinExplan}>
                        <strong className={styles.selectedPinName}>{usedPin?.name}</strong>
                        <p className={styles.selectedPinExplan}>{usedPin?.description}</p>
                    </div>
                </div>

                <div className={styles.customPinList}>
                    <ul>
                        {customPinList.map((listItem, index) => {
                            return (
                                <CustomPinItem
                                    key={index}
                                    customPinListItem={listItem}
                                    getCustomPinListData={getCustomPinListData}
                                    onSelectPin={handleSelectPin}
                                    isSelected={selectedPin?.id === listItem.id}
                                />
                            );
                        })}
                    </ul>
                </div>
            </div>
        </Layout>
    );
}

export default CustomPin;
