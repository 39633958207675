import axioswrapper from './Axios';
import {localStorageUtil} from './LocalStorage';

export const appUtils = {
    /* 영문, 숫자, 마침표, 밑줄 */
    isValidUserName: (userName) => {
        return /^[A-Za-z0-9._]+$/.test(userName) || userName === '';
    },
    /* 대소문자 구분없이 동일한 문자열인지 체크 */
    isSameIgnoreCase: (prev, next) => {
        return prev.toLowerCase() === next.toLowerCase();
    },
    /**
     * yyyy-mm-dd
     * @param isoString
     */
    dateFormatTypeDash: (isoString) => {
        if (!isoString) return '';
        const d = new Date(isoString);
        const ye = new Intl.DateTimeFormat('en', {year: 'numeric'}).format(d);
        const mo = new Intl.DateTimeFormat('en', {month: '2-digit'}).format(d);
        const da = new Intl.DateTimeFormat('en', {day: '2-digit'}).format(d);

        return `${ye}-${mo}-${da}`;
    },
    /**
     * yyyy년 mm월 dd일
     * @param isoString
     */
    nowDateToYYYYMMDD: (isoString) => {
        if (!isoString) return '';
        const d = new Date(isoString);
        const ye = new Intl.DateTimeFormat('en', {year: 'numeric'}).format(d);
        const mo = new Intl.DateTimeFormat('en', {month: '2-digit'}).format(d);
        const da = new Intl.DateTimeFormat('en', {day: '2-digit'}).format(d);
        //const dd = new Intl.DateTimeFormat('ko-KR', {weekday: 'short'}).format(d);

        return `${ye}년 ${mo}월 ${da}일`;
    },
    /**
     * YYYY-MM-DD hh:mm:ss
     * @param createdAt
     */
    getDifferenceInTime: (createdAt) => {
        // 현재 시간을 가져옵니다.
        const now = new Date();

        // 데이터 시간을 설정합니다.
        const dataTime = new Date(createdAt.replace(' ', 'T'));

        // 시간 차이를 밀리초 단위로 계산합니다.
        const timeDifference = now - dataTime;

        // 밀리초를 초, 분, 시간, 일로 변환합니다.
        const seconds = Math.floor((timeDifference / 1000) % 60);
        const minutes = Math.floor((timeDifference / (1000 * 60)) % 60);
        const hours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24);
        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

        if (days > 0) {
            const weeks = days / 7;
            if (weeks >= 1) {
                return `${weeks.toFixed(0)}주전`;
            }
            return `${days}일전`;
        } else if (hours > 0) {
            return `${hours}시간전`;
        } else if (minutes > 0) {
            return `${minutes}분전`;
        } else if (seconds > 0) {
            return `${seconds}초전`;
        }
    },
    /**
     * 로그 메세지 전달
     * @param loc
     * @param message
     * @param type
     */
    sendLogMessage: (loc, message, type) => {
        const payload = {
            memberId: localStorageUtil.get('memberId'),
            name: localStorageUtil.get('userName'),
            loc: loc,
            message: message,
            type: type
        };
        axioswrapper.Axios('POST', 'log/create', payload).then((response) => {
            console.log('log/create ok', response);
        });
    }
};

// 퍼미션 결과
export const RESULTS = Object.freeze({
    UNAVAILABLE: 'UNAVAILABLE',
    BLOCKED: 'BLOCKED',
    DENIED: 'DENIED',
    GRANTED: 'GRANTED',
    LIMITED: 'LIMITED'
});

// 카메라, 앨범/사진첩 퍼미션
export const getImagePermission = async () => {
    const webview = window.ReactNativeWebView;
    if (!webview) return;

    const permission = localStorageUtil.get('photoPermission');
    const camera = localStorageUtil.get('CAMERA');
    const photo = localStorageUtil.get('PHOTO');

    // 카메라 접근 권한 허용 여부 확인
    // if (!camera || camera !== RESULTS.GRANTED) {
    webview.postMessage(
        JSON.stringify({
            type: 'CAMERA'
        })
    );
    // }

    // 앨범/사진첩 접근 권한 허용 여부 확인
    // if (!photo || photo !== RESULTS.GRANTED) {
    webview.postMessage(
        JSON.stringify({
            type: 'PHOTO'
        })
    );
    // }

    // 카메라, 앨범/사진첩 접근 권한이 허용여부 로컬스토리지에 담기
    if (camera && photo) {
        if (camera === RESULTS.GRANTED && photo === RESULTS.GRANTED) {
            localStorageUtil.set('photoPermission', true);
        } else {
            localStorageUtil.set('photoPermission', false);
        }
    } else {
        localStorageUtil.set('photoPermission', false);
    }
};
