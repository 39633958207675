import React, {useState} from 'react';
import Layout from '../../components/Layout/Layout';
import SearchInput from '../../components/Input/SearchInput/SearchInput';
import './Search.scss';
import UserIdList from '../../components/List/UserIdList/UserIdList';

function SearchResult(props) {

    const list = history.state.usr

    // 검색
    const [searchValue, setSearchValue] = useState('');
    const handleInputChange = (value) => {
        setSearchValue(value);
    };

    return (
        <Layout pageTitle="검색" back={true}>
            <div id="searchPage" className="innerWrap">
                <SearchInput onInputChange={handleInputChange} placeholder="아이디로 검색" />

                <div className="schConArea">
                    <div className="totalCountArea">
                        검색결과 <strong>{list.length}</strong>
                    </div>

                    <UserIdList feedDTO={list} searchValue={searchValue} />
                </div>

                {/* 실시간 검색 */}
                <div className="schConArea" style={{ display: searchValue ? 'block' : 'none' }}>
                    <UserIdList schRealTime={true} feedDTO={list.filter((item) => {return item.nickname.includes({searchValue})})} searchValue={searchValue}/>
                </div>
            </div>
        </Layout>
    );
}

export default SearchResult;
