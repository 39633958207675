import {history} from '../_helpers/history';

const stackLength = localStorage.getItem('stackLength') || 1;

// react native app 환경인지 판단
const isApp = () => {
    let isApp = false;

    if (typeof window !== 'undefined' && window.ReactNativeWebView) {
        isApp = true;
    }

    return isApp;
};

// ReactNative Webview에 postMessage 요청
const sendRouterEvent = (navEventType, screenView, url) => {
    window.ReactNativeWebView.postMessage(
        JSON.stringify({
            type: 'ROUTER_EVENT',
            animationType: navEventType,
            screen: screenView,
            path: url
        })
    );
};

// 뒤로가기 하는 경우
// 뒤로가기로 스택을 제거하면서 이전 페이지를 리렌더링 하고 싶으면 screenView와 url을 꼭 같이 보내야 합니다.
export const stackRouterBack = (screenView = null, url = null) => {
    if (isApp()) {
        sendRouterEvent('goBack', screenView, url);
    } else {
        history.navigate(-1);
    }
};

// 스택을 쌓고 라우터를 이동하는 경우
export const stackRouterPush = (screenView, url) => {
    if (isApp()) {
        sendRouterEvent('push', screenView, url);
    } else {
        history.navigate(url);
    }
};

// 네비게이션 스택에 동일한 스크린이 이미 존재할 경우, 해당 화면을 다시 렌더링하지 않고 그 화면으로 이동.
export const stackRouterNavigate = (screenView, url) => {
    if (isApp()) {
        if (stackLength > 1) {
            sendRouterEvent('navigate', screenView, url);
        } else {
            history.navigate(url);
        }
    } else {
        history.navigate(url);
    }
};

// 스택을 쌓지 않고 라우터를 이동하는 경우
// 스택이 쌓여있는 경우에 사용하면 이전 스택이 해당 스크린으로 교체되므로 신중히 사용해야 합니다.
export const stackRouterReplace = (screenView, url) => {
    if (isApp()) {
        if (stackLength > 1) {
            sendRouterEvent('replace', screenView, url);
        } else {
            history.navigate(url);
        }
    } else {
        history.navigate(url);
    }
};

// 모든 스택을 초기화하고 라우터를 이동하는 경우
export const stackRouterReset = (screenView, url) => {
    if (isApp()) {
        sendRouterEvent('reset', screenView, url);
    } else {
        if (window.location.pathname === url) {
            window.location.reload(); // 현재 URL과 동일하면 새로고침
        } else {
            history.navigate(url);
        }
    }
};
