import React from 'react';
import {Route, Routes} from 'react-router-dom';

import {PrivateRoute} from './_helpers/PrivateRoute';
import CustomPin from './pages/CustomPin/CustomPin';
import FeedDetail from './pages/Feed/FeedDetail';
import FeedIndex from './pages/Feed/FeedIndex';
import Follow from './pages/Follow/Follow';
import Join from './pages/Join/Join';
import Login from './pages/Login/Login';
import ModifyProfile from './pages/MyPage/ModifyProfile';
import Notification from './pages/Notification/Notification';
import Search from './pages/Search/Search';
import SearchResult from './pages/Search/SearchResult';
import BanUserPage from './pages/Setting/BanUser/BanUserPage';
import BlackListPage from './pages/Setting/BlackList/BlackListPage';
import OpenLicense from './pages/Setting/Licenses/OpenLicense';
import PrivacyPolicy from './pages/Setting/Privacy/PrivacyPolicy';
import WepinAgreement from './pages/Setting/Privacy/WepinAgreement';
import SettingMenu from './pages/Setting/SettingMenu';
import NotiSetting from './pages/Setting/notiSetting/NotiSetting';
import Home from './pages/home/Home';
import TmapTestPage from './pages/tmapTestPage/TmapTestPage';

function AppRoutes() {
    const pathList = [
        {
            path: '/',
            element: <Home />,
            isLogin: true
        },
        {
            path: '/login',
            element: <Login />,
            isLogin: false
        },
        {
            path: '/join',
            element: <Join />,
            isLogin: false
        },
        {
            path: '/tmapTestPage',
            element: <TmapTestPage />,
            isLogin: true
        },
        {
            path: '/search',
            element: <Search />,
            isLogin: true
        },
        {
            path: '/search/result',
            element: <SearchResult />,
            isLogin: true
        },
        {
            path: '/feed/detail',
            element: <FeedDetail />,
            isLogin: true
        },
        {
            path: '/feed/index',
            element: <FeedIndex />,
            isLogin: true
        },
        {
            path: '/follow/:memberId',
            element: <Follow />,
            isLogin: true
        },
        {
            path: '/mypage/profile/modify',
            element: <ModifyProfile />,
            isLogin: true
        },
        {
            path: '/setting',
            element: <SettingMenu />,
            isLogin: true
        },
        {
            path: '/privacy/privacyPolicy',
            element: <PrivacyPolicy />,
            isLogin: true
        },
        {
            path: '/privacy/agreement',
            element: <WepinAgreement />,
            isLogin: true
        },
        {
            path: '/privacy/openLicense',
            element: <OpenLicense />,
            isLogin: true
        },
        {
            path: '/setting/backList',
            element: <BlackListPage />,
            isLogin: true
        },
        {
            path: '/setting/blockUser',
            element: <BanUserPage />,
            isLogin: true
        },
        {
            path: '/setting/notiSetting',
            element: <NotiSetting />,
            isLogin: true
        },
        {
            path: '/notification',
            element: <Notification />,
            isLogin: true
        },
        {
            path: '/customPin',
            element: <CustomPin />,
            isLogin: true
        }
    ];

    return (
        <Routes>
            {pathList.map((v, idx) => {
                if (v.isLogin) {
                    return (
                        <Route key={idx} path={v?.path} element={<PrivateRoute>{v?.element}</PrivateRoute>} />
                    );
                } else {
                    return <Route key={idx} path={v?.path} element={v?.element} />;
                }
            })}
        </Routes>
    );
}

export default AppRoutes;
