import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';
import {LoadingButton} from '@mui/lab';
import {Drawer, FormControl, FormControlLabel, Radio, RadioGroup, TextareaAutosize} from '@mui/material';

import {stackRouterReset} from '../../../_helpers/StackRouter';
import {setReportDrawerOpen} from '../../../_store/report.slice';
import axioswrapper from '../../../utils/Axios';
import {localStorageUtil} from '../../../utils/LocalStorage';
import IconButton from '../../Button/IconButton/IconButton';
import './ReportDrawer.scss';

function ReportDrawer(props) {
    const {pinDetailData} = props;
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false); // 신고하기 Post중일때 꼭 true로 바꿔주기!!!
    const [activeButton, setActiveButton] = useState(false);
    const [textAreaValue, setTextAreaValue] = useState('');
    const [blockReportCode, setBlockReportCode] = useState('');
    const [blockReportList, setBlockReportList] = useState([]);

    const {reportDrawerOpen} = useSelector((state) => state.report);
    const {userFeedList} = useSelector((state) => state.feed);

    useEffect(() => {
        if (reportDrawerOpen) {
            axioswrapper
                .Axios('GET', 'code/list?cdClsId=BLOCK')
                .then((response) => {
                    setBlockReportList(response.data.data);
                })
                .catch((error) => {
                    console.log('code/list error', error);
                });
        }
    }, [reportDrawerOpen]);

    // textArea 입력란
    const handleTextAreaChange = (event) => {
        const newValue = event.target.value;
        setTextAreaValue(newValue);
    };

    // 라디오 버튼 체크되었다면 '신고하기' 버튼 활성화
    const handleRadioChange = (event) => {
        setActiveButton(true);
        setBlockReportCode(event.target.value);
    };

    // 신고하기 취소(드로어 닫기)
    const canclePostReport = () => {
        dispatch(setReportDrawerOpen(false));

        // 입력 초기화
        setTextAreaValue('');

        //버튼 초기화
        setActiveButton(false);
    };

    const blockReportBtnClick = () => {
        let payload = {};
        if (pinDetailData === undefined) {
            payload = {
                blackId: userFeedList.memberId,
                detail: textAreaValue,
                feedId: '',
                memberId: localStorageUtil.get('memberId'),
                reason: blockReportCode
            };
        } else {
            payload = {
                blackId: pinDetailData.memberId,
                detail: textAreaValue,
                feedId: pinDetailData.id,
                memberId: localStorageUtil.get('memberId'),
                reason: blockReportCode
            };
        }

        setLoading(true);
        axioswrapper
            .Axios('POST', 'black/request', payload)
            .then((response) => {
                console.log('black/request ok', response);
                setLoading(false);
                dispatch(setReportDrawerOpen(false));
                stackRouterReset('Home', '/');
            })
            .catch((error) => {
                console.log('black/request error', error);
                setLoading(false);
                dispatch(setReportDrawerOpen(false));
            });
    };

    return (
        <>
            <Drawer anchor="bottom" open={reportDrawerOpen} onClose={canclePostReport} id="reportDrawer">
                <div className="drawerWrap">
                    <div className="drawerTitleArea">
                        <h3 className="title">신고사유</h3>
                        <IconButton name="close" width="24" height="24" onClick={canclePostReport} />
                    </div>
                    <div className="reportContents">
                        <div>
                            <FormControl className="reportReasonList">
                                <RadioGroup name="radio-buttons-group" onChange={handleRadioChange}>
                                    {blockReportList.map((item, idx) => {
                                        return (
                                            <FormControlLabel
                                                key={idx}
                                                value={item.cdId}
                                                control={<Radio />}
                                                label={item.cdNm}
                                            />
                                        );
                                    })}
                                </RadioGroup>
                            </FormControl>
                            <TextareaAutosize
                                minRows={6}
                                maxRows={6}
                                value={textAreaValue}
                                onChange={handleTextAreaChange}
                                placeholder="상세한 신고 내용을 작성해주세요"
                            ></TextareaAutosize>
                            <p className="infoTxt">신고된 게시물 및 사용자는 더이상 보이지 않아요</p>
                        </div>
                    </div>
                    <div className="darwerBtnArea">
                        <LoadingButton
                            variant="contained"
                            fullWidth={true}
                            size="large"
                            loading={loading} // 로딩중 유무
                            loadingPosition="start"
                            startIcon={<LoginOutlinedIcon />}
                            disabled={!activeButton}
                            onClick={blockReportBtnClick}
                        >
                            신고하기
                        </LoadingButton>
                    </div>
                </div>
            </Drawer>

            {loading && <div className="preventPageClickArea"></div>}
        </>
    );
}

export default ReportDrawer;
