import React, {useEffect, useState} from 'react';

function NaverMap(props) {
    const {pinList, openPinDetailDrawer, receivePinData, zoomLevel, mainPinIcon, subPinIcon} = props;
    const [myMap, setMyMap] = useState(null);
    const myPinMap = new Map();
    let currentMainPinId = '';

    useEffect(() => {
        setMyMap(
            new window.naver.maps.Map('map', {
                center: new window.naver.maps.LatLng(37.5652045, 127.98702028),
                zoom: zoomLevel
            })
        );
    }, []);

    useEffect(() => {
        myMap?.setZoom(zoomLevel);
    }, [zoomLevel]);

    useEffect(() => {
        if (pinList.length === 0) {
            return;
        }

        const tempNavermapPinList = [];
        let tempNavermapPin;
        let mainPinPos;

        pinList.forEach((pin) => {
            // pinData를 tmapMarker에 담아서 객체 생성 후 Map에 저장
            if (pin.isMain) {
                tempNavermapPin = makeMaker(mainPinIcon, pin, true);
                mainPinPos = tempNavermapPin;
                myPinMap.set(pin.id, tempNavermapPin);
                myMap.setCenter(new window.naver.maps.LatLng(pin.lat, pin.lng));
            } else {
                tempNavermapPin = makeMaker(subPinIcon, pin, false);
                myPinMap.set(pin.id, tempNavermapPin);
            }

            tempNavermapPinList.push(tempNavermapPin);
        });
    }, [pinList]);

    const makeMaker = (pinIconUrl = '', pinData, isMainPin) => {
        // 커스컴 마커 사이즈 scale 적용
        let mapIconSize = new window.naver.maps.Size(24, 28.5);
        if (isMainPin) {
            mapIconSize = new window.naver.maps.Size(46, 58);
        }

        const myPin = new window.naver.maps.Marker({
            position: new window.naver.maps.LatLng(pinData.lat, pinData.lng),
            map: myMap,
            icon: {
                url: pinIconUrl,
                size: mapIconSize,
                scaledSize: mapIconSize
            }
            // icon: pinIconUrl
        });

        myPin.pinData = pinData;
        myPin.isMainPin = isMainPin;

        if (isMainPin) {
            currentMainPinId = pinData.id;
        }

        window.naver.maps.Event.addListener(myPin, 'click', onClickPin);
        return myPin;
    };

    const onClickPin = (evt) => {
        const pinData = evt.overlay.pinData;
        sendPinData(pinData);

        // 지도 센터 포지션으로 이동
        myMap.panTo(new window.naver.maps.LatLng(pinData.lat, pinData.lng), {
            duration: 300,
            easing: 'linear'
        });

        // 현재의 메인핀을 클릭했을때는 drawer오픈만 하고 아무것도 하지않음
        if (pinData.id === currentMainPinId) {
            // 하단 drawer 오픈
            openPinDetailDrawer();
            return;
        }

        // 이전 메인 pin 아이콘 처리
        if (myPinMap.has(currentMainPinId)) {
            evt.overlay.setMap(null);
            myPinMap.get(currentMainPinId).setMap(null);
            myPinMap.set(
                currentMainPinId,
                makeMaker(subPinIcon, myPinMap.get(currentMainPinId).pinData, false)
            );
        }

        // 현재 클릭된 pin 아이콘 처리
        myPinMap.set(pinData.id, makeMaker(mainPinIcon, myPinMap.get(pinData.id).pinData, true));
        currentMainPinId = pinData.id;

        // 하단 drawer 오픈
        openPinDetailDrawer();
    };

    const sendPinData = (data) => {
        receivePinData(data);
    };

    const findLongerLine = (tempNavermapPinList) => {
        var proj = myMap.getProjection();
        var distance = 0.0;
        const mainPin = tempNavermapPinList.find((item) => item.pinData.isMain);
        var furthestWayPin = mainPin;

        for (var i = 0; i < tempNavermapPinList.length; i++) {
            if (tempNavermapPinList[i].pinData.isMain) {
                continue;
            }

            const newDistance = proj.getDistance(
                new window.naver.maps.LatLng(mainPin.position._lat, mainPin.position._lng),
                new window.naver.maps.LatLng(
                    tempNavermapPinList[i].position._lat,
                    tempNavermapPinList[i].position._lng
                )
            );

            if (newDistance > distance) {
                distance = newDistance;
                furthestWayPin = tempNavermapPinList[i];
            }
        }

        return furthestWayPin;
    };

    return <div id="map" style={{width: '100%', height: '100%'}}></div>;
}

export default NaverMap;
