import React, {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';

import {localStorageUtil} from '../../utils/LocalStorage';

function BasicMap(props) {
    const [myMap, setMyMap] = useState(null);

    const mapRef = useRef(null);
    const {naver} = window;

    const {lat, lng} = useSelector((state) => state.makePin.placeInfo);
    const location = new naver.maps.LatLng(lat, lng);

    useEffect(() => {
        // Naver Map API가 window 객체에서 사용 가능한지 확인
        if (!naver || !mapRef.current) {
            console.error('Naver Map API가 로드되지 않았습니다.');
            return;
        }

        setMyMap(
            new naver.maps.Map(mapRef.current, {
                center: location,
                width: '100%',
                height: '100%',
                zoom: 16
            })
        );

        return () => {
            // Cleanup any resources if necessary
            setMyMap(null);
        };
    }, []);

    useEffect(() => {
        if (naver || mapRef.current) {
            const markerOptions = {
                position: location,
                map: myMap,
                icon: {
                    content: `<img src="${localStorageUtil.get('mainPinIcon')}" style="width: 46px; height: auto;">`,
                    size: new naver.maps.Size(46, 58),
                    origin: new naver.maps.Point(0, 0),
                    anchor: new naver.maps.Point(20.5, 49)
                }
            };

            const marker = new naver.maps.Marker(markerOptions);
        }
    }, [location, myMap, naver]);

    return <div id="map_div" ref={mapRef} style={{width: '100%', height: '100%'}}></div>;
}

export default BasicMap;
