import React, {useEffect, useState} from 'react';

import {TabContext, TabList, TabPanel} from '@mui/lab';
import {Tab} from '@mui/material';

import Layout from '../../../components/Layout/Layout';
import BlackList from '../../../components/List/BlackList/BlackList';
import axioswrapper from '../../../utils/Axios';
import styles from './BlackListPage.module.scss';

function BlackListPage(props) {
    const [value, setValue] = useState('REQUEST');
    const [requestData, setRequestData] = useState([]);
    const [blackData, setBlackData] = useState([]);

    // 접수 목록 조회
    const getRequestList = () => {
        axioswrapper
            .Axios('GET', 'black/list?size=50&status=REQUEST')
            .then((response) => {
                // console.log('접수 목록 조회 성공', response.data.data.lists);
                setRequestData(response.data.data.lists);
            })
            .catch((error) => {
                console.log('접수 목록 조회 실패', error);
            });
    };

    // 완료 목록 조회
    const getBlackList = () => {
        axioswrapper
            .Axios('GET', 'black/list?size=50&status=BLACK')
            .then((response) => {
                // console.log('완료 목록 조회 성공', response.data.data.lists);
                setBlackData(response.data.data.lists);
            })
            .catch((error) => {
                console.log('완료 목록 조회 실패', error);
            });
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);

        if (newValue === 'REQUEST') {
            // 접수 리스트 불러오기
            getRequestList();
        } else if (newValue === 'BLACK') {
            // 완료 리스트 불러오기
            getBlackList();
        }
    };

    useEffect(() => {
        getRequestList();
        getBlackList();
    }, []);

    return (
        <Layout pageTitle="블랙리스트 관리" back={true} activeNav="myPage">
            <div id={styles.blackListPage} className={styles.scrollAreaWrap}>
                <TabContext value={value}>
                    <div className={styles.tabMenu}>
                        <TabList
                            variant="fullWidth"
                            textColor="secondary"
                            indicatorColor="secondary"
                            onChange={handleChange}
                        >
                            <Tab label="접수" value="REQUEST" />
                            <Tab label="완료" value="BLACK" />
                        </TabList>
                    </div>
                    <div className={styles.tabContents}>
                        <TabPanel value="REQUEST">
                            <BlackList
                                listData={requestData}
                                tabValue="REQUEST"
                                getRequestList={getRequestList}
                            />
                        </TabPanel>
                        <TabPanel value="BLACK">
                            <BlackList listData={blackData} tabValue="BLACK" getBlackList={getBlackList} />
                        </TabPanel>
                    </div>
                </TabContext>
            </div>
        </Layout>
    );
}

export default BlackListPage;
