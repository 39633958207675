import React, {useEffect, useState} from 'react';
import {Button, Drawer, FormGroup} from '@mui/material';
import CustomCheckbox from '../../Input/Checkbox/CustomCheckbox';
import PolicyLayer from '../../Layer/PolicyLayer/PolicyLayer';
import Icon from '../../Icon/Icon';

function TermsDrawer(props) {
    const [termsDrawerOpen, setTermsDrawerOpen] = useState(true);
    const [disabledButton, setDisabledButton] = useState(true);

    const [checkList, setCheckList] = useState([
        {
            label: '개인정보처리방침',
            require: true,
            checked: false,
            type: 'JOIN_PRIVACY'
        },
        {
            label: '이용 약관',
            require: true,
            checked: false,
            type: 'JOIN_SERVICE'
        }
    ]);

    const [allChecked, setAllChecked] = useState(false);

    // 약관 동의 팝업
    const [agreementOpen, setAgreementOpen] = useState(false);
    const [agreeLayerTitle, setAgreeLayerTitle] = useState('');
    const [agreeType, setAgreeType] = useState('');

    const onCheckClick = (index) => {
        const checkSet = [...checkList];
        const checkedItem = {
            ...checkList[index],
            checked: !checkList[index].checked
        };
        checkSet[index] = checkedItem;

        setCheckList(checkSet);
        setAllChecked(isAllChecked(checkSet));
    };

    const isAllChecked = (checkSet) => {
        const checkedCnt = checkSet.filter((v) => {
            return v.checked;
        }).length;
        return checkSet.length === checkedCnt;
    };

    const handleAllAgreedChange = (event) => {
        setAllChecked(!allChecked);
        setCheckList((checks) =>
            checks.map((c) => ({
                ...c,
                checked: !allChecked
            }))
        );
    };

    const agreementLayerOpen = (e) => {
        setAgreeLayerTitle(e.currentTarget.dataset.title);
        setAgreeType(e.currentTarget.dataset.type);
        setAgreementOpen(true);
    };

    const agreementLayerClose = () => {
        setAgreementOpen(false);
    };

    // 동의하고 계속하기
    const agreeCheckDone = () => {
        setTermsDrawerOpen(false);
    };

    useEffect(() => {
        if (allChecked === true) {
            setDisabledButton(false);
        } else {
            setDisabledButton(true);
        }
    }, [allChecked]);

    return (
        <>
            <Drawer anchor="bottom" open={termsDrawerOpen} className="termsDrawer">
                <div className="drawerWrap">
                    <div className="drawerTitleArea">
                        <h3 className="title">서비스 약관</h3>
                    </div>
                    <div className="drawerContents">
                        <div className="section">
                            <div className="termsAreaWrap">
                                <div className="allCheckControl">
                                    <CustomCheckbox
                                        checked={allChecked}
                                        onChange={handleAllAgreedChange}
                                        label="전체 동의"
                                        checkStyle="circle"
                                    />
                                </div>
                                <FormGroup>
                                    <ul className="agreeCheckList">
                                        {checkList.map((item, idx) => {
                                            return (
                                                <li key={idx}>
                                                    <CustomCheckbox
                                                        checked={item.checked}
                                                        label={item.require && '[필수] ' + item.label}
                                                        onChange={() => {
                                                            onCheckClick(idx);
                                                        }}
                                                    />
                                                    {item.type && (
                                                        <button
                                                            type="button"
                                                            className="btn_ar"
                                                            onClick={agreementLayerOpen}
                                                            data-title={item.label}
                                                            data-type={item.type}
                                                        >
                                                            <Icon
                                                                width="14"
                                                                height="15"
                                                                viewBox="0 0 14 15"
                                                                fill="#A7A7A7"
                                                                name="arr_right"
                                                            />
                                                        </button>
                                                    )}
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </FormGroup>
                            </div>
                            <div className="fixedBottomBtnArea">
                                <Button
                                    variant="contained"
                                    fullWidth={true}
                                    size="large"
                                    disabled={disabledButton}
                                    onClick={agreeCheckDone}
                                >
                                    동의하고 계속하기
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Drawer>

            <PolicyLayer
                open={agreementOpen}
                handleClose={agreementLayerClose}
                title={agreeLayerTitle}
                agreeType={agreeType}
            />
        </>
    );
}

export default TermsDrawer;
