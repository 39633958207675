import React, {useState} from 'react';
import {TextField} from '@mui/material';
import Icon from '../../Icon/Icon';
import styles from './TextInput.module.scss';

function TextInput(props) {
    const {size, onInputChange, placeholder, value} = props;

    const [inputValue, setInputValue] = useState(props.value ? props.value : '');
    const [isFocused, setIsFocused] = useState(false);

    const onChangeInputValue = (event) => {
        const value = event.target.value;
        setInputValue(value);
        onInputChange(value);
    };

    const handleDeleteClick = () => {
        setInputValue('');
        onInputChange('');
    };

    const handleInputBlur = () => {
        // 포커스를 잃었을 때 일정 지연 후에 포커스 상태 업데이트
        setTimeout(() => {
            setIsFocused(false);
        }, 100);
    };

    const handleInputFocus = () => {
        setIsFocused(true);
    };

    return (
        <div className={styles.textInputArea}>
            <TextField
                size={size}
                value={inputValue}
                placeholder={placeholder}
                onBlur={handleInputBlur}
                onFocus={handleInputFocus}
                onChange={onChangeInputValue}
            />

            {/* 텍스트 삭제 버튼 */}
            {isFocused && inputValue && (
                <button type="button" className={styles.btnTxtDelete} onClick={handleDeleteClick}>
                    <Icon name="delete" width="17" height="17" fill="#bbb" />
                </button>
            )}
        </div>
    );
}

export default TextInput;
