import React, {useEffect, useState} from 'react';

import {Avatar} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import Layout from '../../components/Layout/Layout';
import {appUtils} from '../../utils/AppUtils';
import axioswrapper from '../../utils/Axios';
import {localStorageUtil} from '../../utils/LocalStorage';
import styles from './Notification.module.scss';
import NotificationItem from './NotificationItem';

function Notification() {
    const [pushHistoryList, setPushHistoryList] = useState([]);
    const [loadPageNum, setLoadPageNum] = useState(0);
    const [isPageHasNext, setIsPageHasNext] = useState(false);
    const [isScrollBottom, setIsScrollBottom] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const getPushHistoryList = (pageNum) => {
        setIsLoading(true);

        axioswrapper
            .Axios(
                'GET',
                `push-history/list?memberId=${localStorageUtil.get('memberId')}&size=20&page=${pageNum}`
            )
            .then((response) => {
                setPushHistoryList([...pushHistoryList, ...response.data.data.lists]);
                setIsPageHasNext(response.data.data.hasNext);
                setIsLoading(false);
            })
            .catch((error) => {
                console.log('push-history/list error', error);
            });
    };

    useState(() => {
        window.ReactNativeWebView?.postMessage(JSON.stringify({type: 'notificationEnter'}));
        getPushHistoryList(0);
    }, []);

    useEffect(() => {
        if (isScrollBottom) {
            //page num + 1 증가
            if (isPageHasNext) {
                setLoadPageNum((prevPageNum) => prevPageNum + 1);
                getPushHistoryList(loadPageNum + 1);
            }
        }
    }, [isScrollBottom]);

    const notificationListOnScroll = (event) => {
        const {scrollHeight, scrollTop, clientHeight} = event.target;
        const tolerance = 1; // 허용 오차 범위 (px 단위)

        // 이 조건은 사용자가 스크롤을 최하단 근처로 이동했을 때 true가 됩니다.
        if (scrollHeight - scrollTop - clientHeight <= tolerance) {
            setIsScrollBottom(true);
        } else {
            setIsScrollBottom(false);
        }
    };

    return (
        <Layout pageTitle="알림" back={true} activeNav="myPage">
            <div className={styles.notiListWrap} onScroll={notificationListOnScroll}>
                <ul className={styles.notiList}>
                    {pushHistoryList.map((listItem, idx) => {
                        if (!listItem?.createdAt) {
                            return null;
                        }
                        return (
                            <NotificationItem
                                key={idx}
                                notificationListItem={listItem}
                                differenceInTime={appUtils.getDifferenceInTime(listItem.createdAt)}
                            />
                        );
                    })}
                </ul>

                {/* 댓글 로딩 프로그래스 */}
                {isLoading && (
                    <div className={styles.progressWrap}>
                        <CircularProgress />
                    </div>
                )}
            </div>
        </Layout>
    );
}

export default Notification;
