import React, {useEffect, useState} from 'react';

import {Avatar, Button} from '@mui/material';

import Layout from '../../../components/Layout/Layout';
import LoadingProgress from '../../../components/Loading/LoadingProgress';
import axioswrapper from '../../../utils/Axios';
import {localStorageUtil} from '../../../utils/LocalStorage';
import styles from './BanUserPage.module.scss';

function BanUserPage(props) {
    const [loading, setLoading] = useState(false);
    const [banTotalCount, setBanTotalCount] = useState(0);
    const [banData, setBanData] = useState([]);

    const id = localStorageUtil.get('memberId');

    // 차단 목록 조회
    const getBanList = () => {
        axioswrapper
            .Axios('GET', `ban/list?memberId=${id}&size=10`)
            .then((response) => {
                console.log('차단 목록 조회 성공', response.data.data.lists);
                setBanData(response.data.data.lists);
                setBanTotalCount(response.data.data.totalCount);
            })
            .catch((error) => {
                console.log('차단 목록 조회 실패', error);
            });
    };

    // 차단 해제
    const banDelete = (banUserId) => {
        const payload = {
            id: banUserId
        };

        setLoading(true);

        axioswrapper
            .Axios('POST', 'ban/delete', payload)
            .then((response) => {
                // 리스트 다시 불러오기
                setLoading(false);
                setBanData([]);
                getBanList();
            })
            .catch((error) => {
                setLoading(false);
                console.log('ban/delete error', error);
            });
    };

    useEffect(() => {
        getBanList();
    }, []);

    return (
        <Layout pageTitle="차단 관리" back={true} activeNav="myPage">
            <div id={styles.banUserPage}>
                <div className="innerWrap02">
                    <div className="totalCountArea">
                        차단된 계정 <strong>{banTotalCount}</strong>
                    </div>
                    {loading ? (
                        <LoadingProgress />
                    ) : (
                        <ul className={styles.banUserList}>
                            {banData.map((item, idx) => {
                                return (
                                    <li key={idx}>
                                        <div className={styles.userInfoArea}>
                                            <div className={styles.avatarArea}>
                                                <Avatar src={item.imgUrl} />
                                            </div>
                                            <p className={styles.name}>{item.nickname}</p>
                                        </div>
                                        <div className={styles.btnArea}>
                                            <Button
                                                variant="contained"
                                                size="small"
                                                className={`MuiButton-sizeMedium ${styles.btnMore}`}
                                                color="primary"
                                                onClick={(banUserId) => banDelete(item.id)}
                                            >
                                                차단해제
                                            </Button>
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                    )}
                </div>
            </div>
        </Layout>
    );
}

export default BanUserPage;
