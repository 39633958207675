import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {setLocDrawerOpen} from '../../_store/makePin.slice';

function RealTimeSchList(props) {
    const {resetSearch} = props;

    const dispatch = useDispatch();
    const {pinList} = useSelector((state) => state.makePin);
    const {schKeyword, schList} = useSelector((state) => state.search);

    // 특정 문자열을 <b></b>로 감싸는 함수
    const replaceKeywordWrap = (text) => {
        if (text !== undefined) {
            const lowerText = text.toLowerCase(); // text를 소문자로 변환
            const lowerKeyword = schKeyword.toLowerCase(); // schKeyword를 소문자로 변환

            if (lowerText.includes(lowerKeyword)) {
                const parts = lowerText.split(lowerKeyword);
                let currentIndex = 0;

                return parts.map((part, i) => {
                    const startIndex = currentIndex;
                    const endIndex = currentIndex + part.length;

                    currentIndex = endIndex + schKeyword.length;

                    return (
                        <React.Fragment key={i}>
                            {text.substring(startIndex, endIndex)}
                            {i !== parts.length - 1 && <b>{text.substr(endIndex, schKeyword.length)}</b>}
                        </React.Fragment>
                    );
                });
            } else {
                return text;
            }
        }
    };

    // 지도에서 위치설정하기 드로어 오픈
    const settingOpen = (lat, lng) => {
        // 핀 목록 추가 30개 제한
        if (pinList.length >= 30) {
            window.alert('핀 목록은 30개를 초과하여 등록할 수 없습니다.');
            return;
        }

        dispatch(setLocDrawerOpen({open: true, lat: lat, lng: lng}));

        setTimeout(() => {
            resetSearch();
        }, 300);
    };

    return (
        <div className="schLocListWrap">
            <ul className="schLocList">
                {schList.map((item) => (
                    <li
                        key={item.pkey}
                        onClick={() =>
                            settingOpen(
                                item.newAddressList.newAddress[0].centerLat,
                                item.newAddressList.newAddress[0].centerLon
                            )
                        }
                    >
                        {replaceKeywordWrap(item.newAddressList.newAddress[0]?.fullAddressRoad)}
                        {item.collectionType === 'poi' && <> {replaceKeywordWrap(item.name)}</>}
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default RealTimeSchList;
