import React from 'react';

import {stackRouterPush} from '../../../_helpers/StackRouter';
import styles from './FeedList.module.scss';

function FeedListItem(props) {
    const {feedItem, myIsFollow} = props;

    const onClickFeedDetail = () => {
        const feedId = feedItem.feedId;
        const isFollow = myIsFollow;
        stackRouterPush('FeedDetail', `/feed/detail?feedId=${feedId}&isFollow=${isFollow}`);
    };

    return (
        <li key={feedItem.feedId} onClick={() => onClickFeedDetail()}>
            <div className={styles.previewMap}>
                <img src={feedItem.imgUrl} alt={feedItem.title} />
            </div>
            <div className={styles.pinInfo}>
                <strong className={styles.subject}>{feedItem.title}</strong>
                <span className={styles.count}>{feedItem.pinCnt}핀</span>
            </div>
        </li>
    );
}

export default FeedListItem;
