import React from 'react';
import styles from './Notification.module.scss';
import {Avatar} from '@mui/material';

function NotificationItem(props) {
    const {notificationListItem, differenceInTime} = props;

    const handleNotiClick = () => {
        // 클릭 로직 넣어주세요~~
        console.log('알림 리스트 클릭~~~');
    };

    return (
        <li>
            <div className={styles.userProfileArea} onClick={handleNotiClick}>
                <div className={styles.avatarArea}>
                    <Avatar src={notificationListItem.profile} />
                </div>
                <div className={styles.userInfoArea}>
                    <strong className={styles.userName}>{notificationListItem.message.split('님이')[0]}</strong>님이 {notificationListItem.message.split('님이')[1]}<span className={styles.time}> {differenceInTime}</span>
                </div>
            </div>
        </li>
    )
}

export default NotificationItem;
