import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {setPinInfo} from '../../_store/makePin.slice';
import {localStorageUtil} from '../../utils/LocalStorage';
import {minStaticMapLevel} from '../../utils/constant';

function PreviewMap(props) {
    const {pinList, placeMainInfo} = useSelector((state) => state.makePin);

    const dispatch = useDispatch();

    const mapRef = useRef(null);
    const {naver} = window;
    const [myMap, setMyMap] = useState(null);
    const [zoomLevel, setZoomLevel] = useState(16);

    const myPinMap = new Map();

    const mapWidth = mapRef?.current?.clientWidth;
    const mapHeight = mapRef?.current?.clientHeight;

    // 메인핀 위도/경도
    const centerLat = placeMainInfo.lat;
    const centerLng = placeMainInfo.lng;
    const centerLocation = new naver.maps.LatLng(centerLat, centerLng);

    const makeMaker = (pinIconUrl = '', pinData, isMainPin) => {
        // 커스컴 마커 사이즈 scale 적용
        let mapIconSize = new naver.maps.Size(24, 28.5);
        if (isMainPin) {
            mapIconSize = new naver.maps.Size(46, 58);
        }

        const myPin = new window.naver.maps.Marker({
            position: new window.naver.maps.LatLng(pinData.lat, pinData.lng),
            map: myMap,
            // icon: pinIconUrl
            icon: {
                url: pinIconUrl,
                size: mapIconSize,
                scaledSize: mapIconSize
            }
        });

        myPin.pinData = pinData;
        myPin.isMainPin = isMainPin;

        return myPin;
    };

    const findLongerLine = (tempNavermapPinList) => {
        var proj = myMap.getProjection();
        var distance = 0.0;
        const mainPin = tempNavermapPinList.find((item) => item.pinData.isMain);
        var furthestWayPin = mainPin;

        for (var i = 0; i < tempNavermapPinList.length; i++) {
            if (tempNavermapPinList[i].pinData.isMain) {
                continue;
            }

            const newDistance = proj.getDistance(
                new naver.maps.LatLng(mainPin.position._lat, mainPin.position._lng),
                new naver.maps.LatLng(
                    tempNavermapPinList[i].position._lat,
                    tempNavermapPinList[i].position._lng
                )
            );

            if (newDistance > distance) {
                distance = newDistance;
                furthestWayPin = tempNavermapPinList[i];
            }
        }

        return furthestWayPin;
    };

    useEffect(() => {
        // Naver Map API가 window 객체에서 사용 가능한지 확인
        if (!naver || !mapRef.current) {
            console.error('Naver Map API가 로드되지 않았습니다.');
            return;
        }

        setMyMap(
            new naver.maps.Map(mapRef.current, {
                center: centerLocation,
                zoom: zoomLevel
            })
        );

        return () => {
            setMyMap(null);
        };
    }, []);

    // 맵 줌 계산
    useEffect(() => {
        if (pinList.length === 0 || myMap === null) {
            return;
        }

        // pinList가 바뀌었을때 zoomLevel 초기화
        dispatch(setPinInfo({zoomLevel: null}));

        const tempNavermapPinList = [];
        let tempNavermapPin;
        let mainPinPos;

        pinList.forEach((pin) => {
            // pinData를 tmapMarker에 담아서 객체 생성 후 Map에 저장
            if (pin.isMain) {
                tempNavermapPin = makeMaker(localStorageUtil.get('mainPinIcon'), pin, true);
                mainPinPos = tempNavermapPin;
                myPinMap.set(pin.id, tempNavermapPin);
                myMap.setCenter(new naver.maps.LatLng(pin.lat, pin.lng));
            } else {
                tempNavermapPin = makeMaker(localStorageUtil.get('subPinIcon'), pin, false);
                myPinMap.set(pin.id, tempNavermapPin);
            }

            tempNavermapPinList.push(tempNavermapPin);
        });

        const furthestWayPin = findLongerLine(tempNavermapPinList);
        var furthestWayPinPos = new naver.maps.LatLngBounds(
            new naver.maps.LatLng(mainPinPos.position._lat, mainPinPos.position._lng),
            new naver.maps.LatLng(furthestWayPin.position._lat, furthestWayPin.position._lng)
        );

        let mbounds = myMap.getBounds();
        var mZoom = myMap.getZoom();

        const settingPreviewMap = () => {
            setZoomLevel(mZoom);
            dispatch(setPinInfo({zoomLevel: mZoom}));
        };

        if (!mbounds.hasBounds(furthestWayPinPos)) {
            const intervalId = setInterval(() => {
                mbounds = myMap.getBounds();
                if (mbounds.hasBounds(furthestWayPinPos)) {
                    // 예를 들어, 3번 실행된 후에 작업을 종료
                    clearInterval(intervalId); // setInterval 작업 종료
                    settingPreviewMap();
                } else {
                    mZoom -= 1;
                    if (mZoom < 0) {
                        mZoom = minStaticMapLevel; // 5 아래로는 지도 범위가 너무 전세계라서 5로 지정했습니다...ㅋㅋㅋ
                        clearInterval(intervalId);
                        settingPreviewMap();
                    } else {
                        myMap.setZoom(mZoom, true);
                    }
                }
            }, 200);
        } else {
            dispatch(setPinInfo({zoomLevel: zoomLevel}));
        }
    }, [pinList, myMap]);

    // 맵 컨테이너 사이즈(mapRef)를 제대로 잡지 못해서 추가함...
    useEffect(() => {
        if (myMap !== null) {
            const Size = new naver.maps.Size(mapWidth, mapHeight);
            myMap.setSize(Size);
        }
    }, [mapWidth, mapHeight]);

    return (
        <>
            <div id="map_div" ref={mapRef} style={{width: '100%', height: '100%'}}></div>
        </>
    );
}

export default PreviewMap;
