import React from 'react';
import OpenLicenseCard from './OpenLicenseCard';
import Layout from '../../../components/Layout/Layout';
import styles from './OpenLicense.module.scss';

function OpenLicense(props) {
    return (
        <Layout pageTitle="오픈소스 라이브러리" back={true} nav={false}>
            <div className={styles.openLicenseWrap}>
                <OpenLicenseCard />
            </div>
        </Layout>
    );
}

export default OpenLicense;
