import React, {useEffect, useRef, useState} from 'react';
import {useDispatch} from 'react-redux';

import CameraAltIcon from '@mui/icons-material/CameraAlt';
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';
import {LoadingButton} from '@mui/lab';
import {Avatar, Button, FormControl, InputLabel, TextField, TextareaAutosize} from '@mui/material';
import {styled} from '@mui/material/styles';
import axios from 'axios';

import {stackRouterBack} from '../../_helpers/StackRouter';
import {setWhereCallFrom} from '../../_store/permission.slice';
import Layout from '../../components/Layout/Layout';
import {appUtils} from '../../utils/AppUtils';
import axioswrapper from '../../utils/Axios';
import {localStorageUtil} from '../../utils/LocalStorage';
import getUserAgent from '../../utils/UserAgent';
import styles from './ModifyProfile.module.scss';

let myImgUploadRef = null;
export const openImgLoadWindowModifyProfile = () => {
    myImgUploadRef.current.click();
};

function ModifyProfile(props) {
    const [loading, setLoading] = useState(false);
    const [activeButton, setActiveButton] = useState(true);
    const [memberInfo, setMemberInfo] = useState({});
    const [selectedImage, setSelectedImage] = useState(null);
    const [userNameValue, setUserNameValue] = useState({
        prevValue: '',
        updateValue: ''
    });
    const [nickNameValue, setNickNameValue] = useState({
        prevValue: '',
        updateValue: ''
    });
    const [textAreaValue, setTextAreaValue] = useState('');
    const [isUserNameValid, setIsUserNameValid] = useState(false);
    const [inputUserNameValueHyperText, setInputUserNameValueHyperText] = useState('');
    const [isNickNameValid, setIsNickNameValid] = useState(false);
    const [inputNickNameValueHyperText, setInputNickValueHyperText] = useState('');

    const dispatch = useDispatch();
    const nickNameInputRef = useRef(null);
    const imgUploadRef = useRef(null);
    myImgUploadRef = imgUploadRef;

    const ua = getUserAgent();

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1
    });

    useEffect(() => {
        const myMemberId = localStorageUtil.get('memberId');

        axioswrapper
            .Axios('GET', `member/user-info?memberId=${myMemberId}`)
            .then((response) => {
                console.log('멤버 정보 조회', response.data.data);
                setMemberInfo(response.data.data);
                setUserNameValue({
                    prevValue: response.data.data.name,
                    updateValue: response.data.data.name
                });
                setNickNameValue({
                    prevValue: response.data.data.nickname,
                    updateValue: response.data.data.nickname
                });
                setTextAreaValue(response.data.data.intro || '');
            })
            .catch((error) => {
                console.log('member/user-info error', error);
            });
    }, []);

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        setSelectedImage(file);
    };

    const handleTextAreaChange = (event) => {
        const newValue = event.target.value;
        setTextAreaValue(newValue);
    };

    const handleButtonSubmit = () => {
        setLoading(true);

        // 닉네임 유효성 검사
        if (appUtils.isValidUserName(nickNameValue.updateValue)) {
            setIsNickNameValid(false);
        } else {
            setInputNickValueHyperText('영문, 숫자, 밑줄 및 마침표를 포함한 6자 이상 입력');
            setIsNickNameValid(true);
            nickNameInputRef.current.children[0].children[0].focus(); // 해당 input으로 focus 이동
            setLoading(false);
            return;
        }

        // 이전 닉네임과 동일한지 체크 >> 동일하지 않을때만 아이디 체크 api
        if (nickNameValue.prevValue === nickNameValue.updateValue) {
            postModifyProfile();
        } else {
            axioswrapper
                .Axios('GET', `auth/nickname-check?nickname=${nickNameValue.updateValue}`)
                .then((response) => {
                    // 이미 존재하는 아이디인지 체크
                    if (response.data.data.isExist) {
                        setIsNickNameValid(true);
                        setInputNickValueHyperText('동일한 계정이 존재합니다.');
                        nickNameInputRef.current.children[0].children[0].focus(); // 해당 input으로 focus 이동
                        setLoading(false);
                    } else {
                        postModifyProfile();
                    }
                })
                .catch((error) => {
                    console.log('nickname check error', error);
                    setLoading(false);
                });
        }
    };

    // 프로필 편집 api
    const postModifyProfile = async () => {
        try {
            const formData = new FormData();
            if (selectedImage) {
                formData.append('profile', selectedImage);
            } else {
                formData.append('profile', null);
            }

            formData.append('intro', textAreaValue);
            formData.append('memberId', localStorageUtil.get('memberId'));
            formData.append('name', userNameValue.updateValue);
            formData.append('nickname', nickNameValue.updateValue);
            const apiUrl = `${process.env.REACT_APP_API_URL}/member/user-update`;
            const response = await axios.post(apiUrl, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            console.log('프로필 편집 POST 응답:', response.data);
            setLoading(false);
            localStorageUtil.set('userName', userNameValue.updateValue);
            stackRouterBack(
                'MyFeedIndex',
                `/feed/index?memberId=${localStorageUtil.get('memberId')}&pageType=myPage`
            );
        } catch (error) {
            console.error('프로필 편집 POST 요청 오류:', error);
            setLoading(false);
        }
    };

    // 사진첩, 카메라, 저장공간 접근 권한 체크
    const photoPermissionCheck = () => {
        if (ua !== 'web') {
            console.log('send checkPhotoCameraPermission');
            dispatch(setWhereCallFrom('ModifyProfile'));
            window.ReactNativeWebView?.postMessage(
                JSON.stringify({
                    type: 'checkPhotoCameraPermission'
                })
            );
        } else {
            imgUploadRef.current.click();
        }
    };

    const nickNameChange = (event) => {
        const inputValue = event.target.value;
        setNickNameValue({
            ...nickNameValue,
            updateValue: inputValue
        });

        if (inputValue.length < 6) {
            setInputNickValueHyperText('영문, 숫자, 밑줄 및 마침표를 포함한 6자 이상 입력');
            setIsNickNameValid(true);
            nickNameInputRef.current.children[0].children[0].focus(); // 해당 input으로 focus 이동
        } else {
            setInputNickValueHyperText('');
            setIsNickNameValid(false);
        }
    };

    const userNameChange = (event) => {
        const inputValue = event.target.value;
        setUserNameValue({
            ...userNameValue,
            updateValue: inputValue
        });

        if (inputValue.length < 1) {
            setInputUserNameValueHyperText('이름을 입력해주세요');
            setIsUserNameValid(true);
        } else {
            setInputUserNameValueHyperText('');
            setIsUserNameValid(false);
        }
    };

    // 완료 버튼 활성화 여부
    useEffect(() => {
        if (nickNameValue.updateValue.length >= 6 && userNameValue.updateValue) {
            setActiveButton(true);
        } else {
            setActiveButton(false);
        }
    }, [userNameValue, nickNameValue]);

    return (
        <Layout pageTitle="프로필 편집" back={true} nav={false}>
            <div className={`innerWrap ${styles.modifyProfilePage}`}>
                <div className={styles.userProfileArea}>
                    <div className={styles.profileImgArea}>
                        <div className={styles.uploadImgWrap}>
                            <div className={styles.blockedClick}>
                                <div className={styles.imgArea}>
                                    <Avatar
                                        src={
                                            selectedImage
                                                ? URL.createObjectURL(selectedImage)
                                                : memberInfo.profile
                                        }
                                    />
                                    <Button
                                        component="label"
                                        variant="contained"
                                        startIcon={<CameraAltIcon />}
                                        className={styles.btnImgUpload}
                                        onClick={photoPermissionCheck}
                                    >
                                        <p className={styles.txt}>사진 변경</p>
                                    </Button>

                                    <VisuallyHiddenInput
                                        ref={imgUploadRef}
                                        type="file"
                                        accept="image/*"
                                        accept-language="ko-KR"
                                        onChange={handleImageChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.introductionArea}>
                        <FormControl className={styles.labelList}>
                            <InputLabel>계정</InputLabel>
                            <TextField
                                ref={nickNameInputRef}
                                placeholder={nickNameValue.prevValue}
                                value={nickNameValue.updateValue}
                                onChange={nickNameChange}
                                helperText={inputNickNameValueHyperText}
                                error={isNickNameValid} // 유효성검사 통과 여부
                            />
                        </FormControl>
                        <FormControl className={styles.labelList}>
                            <InputLabel>이름</InputLabel>
                            <TextField
                                placeholder={userNameValue.prevValue}
                                value={userNameValue.updateValue}
                                onChange={userNameChange}
                                helperText={inputUserNameValueHyperText}
                                error={isUserNameValid} // 유효성검사 통과 여부
                            />
                        </FormControl>
                        <FormControl className={styles.labelList}>
                            <InputLabel>소개</InputLabel>
                            <TextareaAutosize
                                minRows={6}
                                maxRows={6}
                                value={textAreaValue}
                                onChange={handleTextAreaChange}
                                placeholder="회원님을 소개하는 내용을 입력해주세요"
                            />
                        </FormControl>
                    </div>
                </div>
                <div className={styles.btnArea}>
                    <LoadingButton
                        variant="contained"
                        fullWidth={true}
                        size="large"
                        loading={loading}
                        loadingPosition="start"
                        startIcon={<LoginOutlinedIcon />}
                        disabled={!activeButton}
                        onClick={handleButtonSubmit}
                    >
                        완료
                    </LoadingButton>
                </div>
            </div>

            {loading && <div className="preventPageClickArea"></div>}
        </Layout>
    );
}

export default ModifyProfile;
