import React from 'react';
import {useDispatch} from 'react-redux';
import {Modal} from '@mui/material';
import './ReportButtonModal.scss';
import {setReportDrawerOpen} from '../../../_store/report.slice';

function ReportButtonModal(props) {
    const dispatch = useDispatch();
    const {open, setOpen, hideBtnClick, whareCalled} = props;

    // 신고하기
    const openReportDrawer = () => {
        onClose();
        dispatch(setReportDrawerOpen(true));
    };

    // 모달 닫기
    const onClose = () => {
        setOpen(false);
    };

    return (
        <Modal open={open} onClose={onClose} className="reportButtonModal">
            <div className="buttonWrap">
                <ul>
                    <li>
                        <button type="button" onClick={() => hideBtnClick(whareCalled)}>
                            {whareCalled === 'feedDetail' ? '숨기기' : '차단하기'}
                        </button>
                    </li>
                    <li>
                        <button type="button" className="red" onClick={openReportDrawer}>
                            신고하기
                        </button>
                    </li>
                    <li>
                        <button type="button" onClick={onClose}>
                            <b>취소</b>
                        </button>
                    </li>
                </ul>
            </div>
        </Modal>
    );
}

export default ReportButtonModal;
