import {createSlice} from '@reduxjs/toolkit';

const name = 'search';
const initialState = {
    schProcess: '',
    schKeyword: '',
    schList: [],
    schTagKeyword: '',
    feedListByTag: [],
    isClickBack: false
};

const searchSlice = createSlice({
    name,
    initialState,
    reducers: {
        setSchProcess: (state, action) => {
            return {
                ...state,
                schProcess: action.payload
            };
        },
        setSchKeyword: (state, action) => {
            state.schKeyword = action.payload;
        },
        setSchList: (state, action) => {
            state.schList = action.payload;
        },
        resetSch: (state, action) => {
            return initialState;
        },
        setFeedListByTag: (state, action) => {
            state.feedListByTag = action.payload;
        },
        setSchTagKeyword: (state, action) => {
            state.schTagKeyword = action.payload;
        },
        setIsClickBack: (state, action) => {
            state.isClickBack = action.payload;
        }
    }
});

export const {
    setSchProcess,
    setSchKeyword,
    setSchList,
    resetSch,
    setFeedListByTag,
    setSchTagKeyword,
    setIsClickBack
} = searchSlice.actions;
export default searchSlice.reducer;
