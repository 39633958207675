import React, { useState } from 'react';
import styles from './CustomPin.module.scss';
import SelectPinModal from './SelectPinModal';

function CustomPinItem(props) {
    const { customPinListItem, getCustomPinListData, onSelectPin, isSelected } = props;

    const [selectPinModalOpen, setSelectPinModalOpen] = useState(false);

    // 핀 사용하기 모달 열기
    const openSelectPinModal = () => {
        setSelectPinModalOpen(true);
        onSelectPin(customPinListItem); 
    };

    // 핀 사용하기 모달 닫기
    const closeSelectPinModal = () => {
        setSelectPinModalOpen(false);
    };

    return (
        <li>
            <button
                type="button"
                className={`${styles.customPinListItem} ${isSelected ? styles.selected : ''}`}
                onClick={openSelectPinModal}
            >
                <div className={styles.customPinListItemImg}>
                    <img src={customPinListItem.mainPin} alt={customPinListItem.name} />
                </div>

                <div className={`${styles.pinInUse} ${customPinListItem.isCurrent ? styles.isUsed : ''}`}>
                    <span>사용중</span>
                </div>
            </button>

            <SelectPinModal
                open={selectPinModalOpen}
                onClose={closeSelectPinModal}
                pinImgSrc={customPinListItem.mainPin}
                pinName={customPinListItem.name}
                pinId={customPinListItem.id}
                endAt={customPinListItem.endAt}
                getCustomPinListData={getCustomPinListData}
            />
        </li>
    );
}

export default CustomPinItem;
