import React from 'react';

import {stackRouterPush} from '../../../_helpers/StackRouter';
import styles from './BestIdList.module.scss';

function BestIdList(props) {
    const clickHandler = (memberId, e) => {
        stackRouterPush('FeedIndex', `/feed/index?memberId=${memberId}`);
    };

    return (
        <div className={styles.userIdListWrap}>
            <ul className={styles.bestIdList}>
                {props?.topList?.map((top, idx) => (
                    <li
                        key={idx}
                        onClick={(e) => {
                            clickHandler(top.memberId, e);
                        }}
                    >
                        <b className={styles.number}>{idx + 1}</b>
                        <p className={styles.userId} data-id={top.memberId}>
                            {top.nickname}
                        </p>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default BestIdList;
