import React from 'react';
import Icon from '../../Icon/Icon';

function IconButton(props) {
    const {name, width, height, fill, onClick, className} = props;

    return (
        <button type="button" onClick={onClick} className={className}>
            <Icon name={name} width={width} height={height} fill={fill} />
        </button>
    );
}

export default IconButton;
