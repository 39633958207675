import {createSlice} from '@reduxjs/toolkit';

const etcSlice = createSlice({
    name: 'etc',
    initialState: {
        appVersion: '',
        backpressedCount: 0,
        appNotiSave: false,
        isPushSetting: false
    },
    reducers: {
        setAppVersion: (state, action) => {
            state.appVersion = action.payload;
        },
        setBackpressedCount: (state, action) => {
            state.backpressedCount = action.payload;
        },
        resetBackpressedCount: (state) => {
            if (state.backpressedCount <= 2) {
                state.backpressedCount = 0;
            }
        },
        setAppNotiSave: (state, action) => {
            state.appNotiSave = action.payload;
        },
        setIsPushSetting: (state, action) => {
            state.isPushSetting = action.payload;
        }
    }
});

export const {setAppVersion, setBackpressedCount, resetBackpressedCount, setAppNotiSave, setIsPushSetting} = etcSlice.actions;
export default etcSlice.reducer;
