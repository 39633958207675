import React from 'react';
import styles from './BlackList.module.scss';
import BlackListItem from './BlackListItem';

function BlackList(props) {
    const {listData, tabValue, getRequestList, getBlackList} = props;

    return (
        <>
            <div className={styles.blackListWrap}>
                <ul className={styles.blackList}>
                    {listData?.map((item) => {
                        return (
                            <BlackListItem
                                key={item.id}
                                item={item}
                                tabValue={tabValue}
                                getRequestList={getRequestList}
                                getBlackList={getBlackList}
                            />
                        );
                    })}
                </ul>
            </div>
        </>
    );
}

export default BlackList;
