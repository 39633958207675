import React from 'react';
import Spinner from '../Icon/Spinner';

function LoadingArea(props) {
    return (
        <div className="preventPageClickArea">
            <Spinner />
        </div>
    );
}

export default LoadingArea;
