import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {TrendingUpRounded} from '@mui/icons-material';

import packageInfo from '../../../package.json';
import {stackRouterPush, stackRouterReset} from '../../_helpers/StackRouter';
import {setAppVersion} from '../../_store/etc.slice';
import Icon from '../../components/Icon/Icon';
import Layout from '../../components/Layout/Layout';
import {appUtils} from '../../utils/AppUtils';
import axioswrapper from '../../utils/Axios';
import {localStorageUtil} from '../../utils/LocalStorage';
import getUserAgent from '../../utils/UserAgent';
import styles from './SettingMenu.module.scss';

function SettingMenu() {
    const {appVersion, isPushSetting} = useSelector((state) => state.etc);
    const dispatch = useDispatch();

    useEffect(() => {
        if (getUserAgent() === 'web') {
            dispatch(setAppVersion(packageInfo.version));
        }
    }, []);

    useEffect(() => {
        if (isPushSetting) {
            stackRouterPush('Setting', '/setting/notiSetting');
        }
    }, [isPushSetting]);

    const logoutBtnClick = () => {
        const loginType = localStorageUtil.get('loginType');
        if (loginType === 'kakao') {
            kakaoLogout();
        } else if (loginType === 'apple') {
            appleLogout();
        }
    };

    const kakaoLogout = () => {
        try {
            const payload = {
                authorizationCode: localStorageUtil.get('accessToken')
            };

            axioswrapper
                .Axios('POST', 'auth/kakao-logout', payload)
                .then((response) => {
                    localStorageUtil.allClear();
                    stackRouterReset('Login', '/login');
                })
                .catch((error) => {
                    console.log('kakao logout error', error);
                });
        } catch (error) {
            console.log('auth/kakao-logout error', error);
        }
    };

    const appleLogout = () => {
        try {
            appUtils.sendLogMessage('SettingMenu.appleLogout()', '', 'NORMAL');

            const payload = {
                authorizationCode: localStorageUtil.get('accessToken')
            };

            axioswrapper
                .Axios('POST', 'auth/apple-logout', payload)
                .then((response) => {
                    localStorageUtil.allClear();
                    stackRouterReset('Login', '/login');
                })
                .catch((error) => {
                    console.log('apple logout error', error);
                });
        } catch (error) {
            console.log('auth/apple-logout error', error);
            appUtils.sendLogMessage('SettingMenu.appleLogout()', error.message, 'ERROR');
        }
    };

    const handleDeleteMember = () => {
        if (getUserAgent() === 'web') {
            if (
                window.confirm(
                    '회원탈퇴 시 작성한 게시물과 팔로우, 팔로워, 회원정보가 전부 삭제됩니다. 진행하시겠습니까?'
                )
            ) {
                const withdrawalUrl =
                    localStorageUtil.get('loginType') === 'kakao' ? 'auth/kakao-unlink' : 'auth/apple-unlink';

                try {
                    appUtils.sendLogMessage('SettingMenu.handleDeleteMember()', '', 'NORMAL');

                    const payload = {
                        authorizationCode: localStorageUtil.get('accessToken'),
                        memberId: localStorageUtil.get('memberId')
                    };

                    axioswrapper
                        .Axios('POST', withdrawalUrl, payload)
                        .then((response) => {
                            console.log('withdrawalUrl ok.. say good bye...see....you.... ;(', response);
                            localStorageUtil.allClear();
                            stackRouterReset('Login', '/login');
                        })
                        .catch((error) => {
                            console.log(`${withdrawalUrl} error`, error);
                        });
                } catch (error) {
                    console.log(`${withdrawalUrl} error`, error);
                    appUtils.sendLogMessage('SettingMenu.handleDeleteMember()', error.message, 'ERROR');
                }
            }
        } else {
            window.ReactNativeWebView?.postMessage(
                JSON.stringify({
                    type: 'isRealDeleteMember'
                })
            );
        }
    };

    const handlePushLinkClick = (event) => {
        if (isPushSetting) {
            stackRouterPush('Setting', '/setting/notiSetting');
        } else {
            window.ReactNativeWebView?.postMessage(JSON.stringify({type: 'requestApplyPushInSetting'}));
            event.preventDefault();
        }
    };

    const handleLinkClick = (url) => {
        stackRouterPush('Setting', url);
    };

    return (
        <Layout pageTitle="설정 및 개인정보" back={TrendingUpRounded} activeNav="myPage">
            <div className={styles.menuWrap}>
                <ul>
                    <li>
                        <button type="button" onClick={() => handleLinkClick('/customPin')}>
                            내 핀 설정
                            <Icon
                                name="arr_right"
                                width="14"
                                height="14"
                                fill="#A7A7A7"
                                className={styles.iconArr}
                            />
                        </button>
                    </li>
                    <li>
                        <button type="button" onClick={handlePushLinkClick}>
                            알림
                            <Icon
                                name="arr_right"
                                width="14"
                                height="14"
                                fill="#A7A7A7"
                                className={styles.iconArr}
                            />
                        </button>
                    </li>
                    <li>
                        <button type="button" onClick={() => handleLinkClick('/privacy/privacyPolicy')}>
                            개인정보처리방침
                            <Icon
                                name="arr_right"
                                width="14"
                                height="14"
                                fill="#A7A7A7"
                                className={styles.iconArr}
                            />
                        </button>
                    </li>
                    <li>
                        <button type="button" onClick={() => handleLinkClick('/privacy/agreement')}>
                            이용 약관
                            <Icon
                                name="arr_right"
                                width="14"
                                height="14"
                                fill="#A7A7A7"
                                className={styles.iconArr}
                            />
                        </button>
                    </li>
                    <li>
                        <button type="button" onClick={() => handleLinkClick('/privacy/openLicense')}>
                            오픈 소스 라이브러리
                            <Icon
                                name="arr_right"
                                width="14"
                                height="14"
                                fill="#A7A7A7"
                                className={styles.iconArr}
                            />
                        </button>
                    </li>
                    <li>
                        <button type="button" onClick={() => handleLinkClick('/setting/blockUser')}>
                            차단 관리
                            <Icon
                                name="arr_right"
                                width="14"
                                height="14"
                                fill="#A7A7A7"
                                className={styles.iconArr}
                            />
                        </button>
                    </li>
                    <li>
                        <a href="mailto:wepinlab@gmail.com">
                            문의하기
                            <Icon
                                name="arr_right"
                                width="14"
                                height="14"
                                fill="#A7A7A7"
                                className={styles.iconArr}
                            />
                        </a>
                    </li>
                    {localStorageUtil.get('loginAuth') === 'ADMIN' && (
                        <li>
                            <button type="button" onClick={() => handleLinkClick('/setting/backList')}>
                                블랙리스트 관리
                                <Icon
                                    name="arr_right"
                                    width="14"
                                    height="14"
                                    fill="#A7A7A7"
                                    className={styles.iconArr}
                                />
                            </button>
                        </li>
                    )}
                    <li>
                        <a>
                            앱 버전
                            <div className={`${styles.iconArr} ${styles.appVersion}`}>
                                {getUserAgent().toUpperCase()} {appVersion}
                            </div>
                        </a>
                    </li>
                    <li>
                        <button type="button" onClick={logoutBtnClick} className={styles.btnLogout}>
                            로그아웃
                        </button>
                    </li>
                    <li>
                        <button type="button" onClick={handleDeleteMember} className={styles.btnLogout}>
                            회원탈퇴
                        </button>
                    </li>
                </ul>
            </div>
        </Layout>
    );
}

export default SettingMenu;
