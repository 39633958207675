import React from 'react';
import styles from './LoadingProgress.module.scss';
import Spinner from '../Icon/Spinner';

function LoadingProgress(props) {
    return (
        <div className={styles.loadingArea}>
            <Spinner />
            <div className={styles.loadingText}>로딩중...</div>
        </div>
    );
}

export default LoadingProgress;
