import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {TextareaAutosize} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import {addReply, setReplyList, setTobeDeletedReplyItem} from '../../_store/reply.slice';
import {appUtils} from '../../utils/AppUtils';
import axioswrapper from '../../utils/Axios';
import {localStorageUtil} from '../../utils/LocalStorage';
import getUserAgent from '../../utils/UserAgent';
import styles from './Comment.module.scss';
import CommentItem from './CommentItem';

function Comment(props) {
    const {visibleCommentInput, replyList, feedId, isMine, isScrollBottom} = props;

    const [commentValue, setCommentValue] = useState();
    const [visibleApplyBtn, setVisibleApplyBtn] = useState(false);
    const [replyPageNum, setReplyPageNum] = useState(0);
    const [replyTotalNum, setReplyTotalNum] = useState(0);
    const [isReplyLoading, setIsReplyLoading] = useState(true);

    const inputRef = useRef(null);
    const endOfPageRef = useRef(null);
    const dispatch = useDispatch();

    const rdxReplyList = useSelector((state) => state.reply.replyList);
    const {tobeDeleteReplyItem} = useSelector((state) => state.reply);

    const [isCommentListExpend, setIsCommentListExpend] = useState(true);

    useEffect(() => {
        // commentlist 초기화
        dispatch(setReplyList([]));
    }, []);

    useEffect(() => {
        // feedId가 설정되면 reply/list api 호출
        if (feedId !== undefined) {
            axioswrapper
                .Axios('GET', `reply/list?feedId=${feedId}&page=${replyPageNum}&size=20`)
                .then((response) => {
                    setReplyTotalNum(response.data.data.totalCount);
                    dispatch(setReplyList(makeUpCommentList(response.data.data.lists)));
                    setIsReplyLoading(false);
                })
                .catch((error) => {
                    console.log('reply/list error', error);
                });
        }
    }, [feedId]);

    useEffect(() => {
        if (visibleCommentInput) {
            inputRef.current.focus();
        }
    }, [visibleCommentInput]);

    useEffect(() => {
        if (isScrollBottom) {
            //댓글 뒤에 이어 부쳐핸섭
            if (rdxReplyList.length === replyTotalNum) {
                setIsReplyLoading(false);
                return;
            }

            // loading프로그래스링 보이기
            setIsReplyLoading(true);

            //page num + 1 증가
            setReplyPageNum((prevReplyPageNum) => prevReplyPageNum + 1);

            axioswrapper
                .Axios('GET', `reply/list?feedId=${feedId}&page=${replyPageNum + 1}&size=20`)
                .then((response) => {
                    const newComments = makeUpCommentList(response.data.data.lists);
                    dispatch(setReplyList([...rdxReplyList, ...newComments]));
                    setIsReplyLoading(false);
                })
                .catch((error) => {
                    console.log('reply/list error', error);
                });
        }
    }, [isScrollBottom]);

    const handleWriteComment = (event) => {
        const inputValue = event.target.value;
        setCommentValue(inputValue);

        if (inputValue.length > 0) {
            setVisibleApplyBtn(true);
        } else {
            setVisibleApplyBtn(false);
        }
    };

    const makeUpCommentList = (replyList) => {
        const tempList = [];

        replyList.forEach((data, idx) => {
            const newItem = {
                id: idx,
                avatarSrc: data.profile,
                name: data.nickNm,
                comment: data.desc,
                replyId: data.id,
                memberId: data.memberId,
                createdAt: data.createdAt
            };

            tempList.push(newItem);
        });

        return tempList;
    };

    const commentUpload = () => {
        if (commentValue.trim() === '') {
            return;
        }

        const payload = {
            desc: commentValue,
            feedId: feedId,
            memberId: localStorageUtil.get('memberId'),
            uiSeq: replyList.length + 1
        };

        axioswrapper
            .Axios('POST', 'reply/create', payload)
            .then((response) => {
                const newCommentItem = {
                    id: replyList.length,
                    avatarSrc: response.data.data.profile,
                    name: response.data.data.nickNm,
                    comment: response.data.data.desc,
                    replyId: response.data.data.id,
                    memberId: response.data.data.memberId,
                    createdAt: response.data.data.createdAt
                };
                dispatch(addReply(newCommentItem));
                setCommentValue('');
                setReplyTotalNum(replyTotalNum + 1);
                if (!isCommentListExpend) {
                    setIsCommentListExpend(true);
                }
            })
            .catch((error) => {
                console.log('reply/create error', error);
            });
    };

    const commentDeleteClick = (item) => {
        // 삭제하기 클릭시 팝업 메뉴 생성
        dispatch(setTobeDeletedReplyItem(item));
        const ua = getUserAgent();
        if (ua === 'web') {
            if (window.confirm('정말로 삭제하시겠습니까?')) {
                // 삭제 로직 처리
                const payload = {
                    replyId: item.replyId
                };

                axioswrapper
                    .Axios('POST', 'reply/delete', payload)
                    .then((response) => {
                        const newCommentList = rdxReplyList.filter((listItem) => listItem !== item);
                        dispatch(setReplyList(newCommentList));
                    })
                    .catch((error) => {
                        console.log('reply/delete error', error);
                    });
            } else {
                console.log('삭제 취소됨');
            }
        } else {
            window.ReactNativeWebView?.postMessage(JSON.stringify({type: 'isRealDeleteComment'}));
        }
    };

    const commentCollapseExpendTextClick = (e) => {
        setIsCommentListExpend(!isCommentListExpend);
    };

    useEffect(() => {
        setReplyTotalNum(rdxReplyList.length);
    }, [rdxReplyList]);

    return (
        <div className={styles.commentWrap}>
            <div>
                <button
                    type="button"
                    className={styles.btnMoreComment}
                    onClick={commentCollapseExpendTextClick}
                >
                    {rdxReplyList.length === 0
                        ? '댓글 0개'
                        : `댓글 ${replyTotalNum}개 ${isCommentListExpend ? '모두접기' : '모두보기'}`}
                </button>

                <div
                    className={styles.commentListWrap}
                    style={{display: isCommentListExpend ? 'block' : 'none'}}
                >
                    {rdxReplyList.map((listItem, idx) => {
                        return (
                            <CommentItem
                                key={idx}
                                commentDeleteClick={commentDeleteClick}
                                listItem={listItem}
                                isVisibleDeleteText={
                                    listItem.memberId === localStorageUtil.get('memberId') || isMine
                                }
                            />
                        );
                    })}

                    <div ref={endOfPageRef} />
                </div>
            </div>

            {/* 댓글 로딩 프로그래스 */}
            {isReplyLoading && (
                <div className={styles.progressWrap}>
                    <CircularProgress />
                </div>
            )}

            {visibleCommentInput && (
                <div className={styles.WriteCommentWrap}>
                    <TextareaAutosize
                        minRows={1}
                        maxRows={4}
                        placeholder="댓글 작성..."
                        value={commentValue}
                        onChange={handleWriteComment}
                        ref={inputRef}
                    />

                    {visibleApplyBtn && (
                        <button type="button" className={styles.btnCommentApply} onClick={commentUpload}>
                            게시
                        </button>
                    )}
                </div>
            )}
        </div>
    );
}

export default Comment;
