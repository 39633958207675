import React from 'react';

import {Avatar} from '@mui/material';

import {stackRouterPush} from '../../_helpers/StackRouter';
import styles from './ProfileArea.module.scss';

function SchProfileArea(props) {
    // 특정 문자열을 <b></b>로 감싸는 함수
    const wrapTextWithBTag = (text, targetText) => {
        return text.split(targetText).join(`<b>${targetText}</b>`);
    };

    const clickHandler = (memberId, e) => {
        stackRouterPush('FeedIndex', `/feed/index?memberId=${memberId}`);
    };

    const wrappedNickname = wrapTextWithBTag(props.profile.nickname, props.searchValue);

    return (
        <div
            className={styles.userProfileArea}
            onClick={(e) => {
                clickHandler(props.profile.memberId, e);
            }}
        >
            <div className={styles.avatarArea}>
                <Avatar src={props.profile.profile} />
            </div>
            <div className={`${styles.userInfoArea} ${styles.hideBtn}`}>
                <strong className={styles.userName}>
                    <span dangerouslySetInnerHTML={{__html: wrappedNickname}} />
                </strong>
            </div>
        </div>
    );
}

export default SchProfileArea;
