import React, {useEffect, useRef, useState} from 'react';

import {Drawer} from '@mui/material';

import Icon from '../../Icon/Icon';
import PhotoModal from '../../Modal/PhotoModal/PhotoModal';
import SearchWayDrawer from '../SearchWayDrawer/SearchWayDrawer';
import styles from './PinDetailDrawer.module.scss';

function PinDetailDrawer(props) {
    const {isOpen, onClose, pinData} = props;
    const [photoModalOpen, setPhotoModalOpen] = useState(false);
    const [schWayDrawerOpen, setSchWayDrawerOpen] = useState(false);

    const refSwiper = useRef();

    // 사진 상세 보기 (자세히보기)
    const openPhotoModal = (idx) => {
        setPhotoModalOpen(true);

        // RN StatusBar 색상 변경
        window.ReactNativeWebView?.postMessage(
            JSON.stringify({
                type: 'statusBar',
                theme: 'dark'
            })
        );

        // RN WebView Gesture 비활성화 (사진 스와이프시 스택 제스처랑 이벤트가 중복 호출되어 뒤로가기 되는 현상 방지)
        window.ReactNativeWebView.postMessage(JSON.stringify({type: 'gestureEnabled', state: false}));

        // 클릭한 사진 보여주기
        if (refSwiper.current && refSwiper.current.swiper) {
            refSwiper.current.swiper.slideTo(idx);
        }
    };

    // 사진 상세 보기 닫기
    const closePhotoModal = () => {
        setPhotoModalOpen(false);

        // RN StatusBar 색상 변경
        window.ReactNativeWebView?.postMessage(
            JSON.stringify({
                type: 'statusBar',
                theme: 'white'
            })
        );

        // RN WebView Gesture 활성화
        window.ReactNativeWebView.postMessage(JSON.stringify({type: 'gestureEnabled', state: true}));

        if (refSwiper.current && refSwiper.current.swiper) {
            refSwiper.current.swiper.slideTo(0);
        }
    };

    // 길찾기 Drawer 열기
    const openSchWayDrawer = () => {
        setSchWayDrawerOpen(true);
    };

    // 길찾기 Drawer 닫기
    const closeSchWayDrawer = () => {
        setSchWayDrawerOpen(false);
    };

    return (
        <>
            <Drawer
                anchor="bottom"
                hideBackdrop={true}
                open={isOpen}
                className={styles.pinDetailDrawer}
                variant="persistent"
            >
                <div className={styles.drawerCon}>
                    <div className={styles.titleArea}>
                        <strong className={styles.title}>{pinData?.name}</strong>
                        <p>
                            {pinData?.addr} {pinData?.addrDetail}
                        </p>
                        <button type="button" className={styles.btnClose} onClick={onClose}>
                            <Icon name="close" width="24" height="24" />
                        </button>
                    </div>
                    <div className={styles.previewImgWrap}>
                        <ul className={styles.previewImgList}>
                            {pinData?.imageList.map((list, idx) => {
                                return (
                                    <li key={list.id} onClick={() => openPhotoModal(idx)}>
                                        <img src={list.imgUrl} alt="" />
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                    <div className={styles.btnArea}>
                        <button type="button" onClick={openSchWayDrawer}>
                            <Icon name="way" width="20" height="20" /> 길찾기
                        </button>
                    </div>
                </div>
            </Drawer>

            {/* 사진 상세 보기 */}
            <PhotoModal
                open={photoModalOpen}
                onClose={closePhotoModal}
                ref={refSwiper}
                data={pinData?.imageList || []}
            />

            {/* 길찾기 */}
            <SearchWayDrawer open={schWayDrawerOpen} onClose={closeSchWayDrawer} pinData={pinData} />
        </>
    );
}

export default PinDetailDrawer;
