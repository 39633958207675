export const localStorageUtil = {
    get: (name) => {
        return localStorage.getItem(name) || null;
    },
    set: (name, value) => {
        localStorage.setItem(name, value);
    },
    remove: (name) => {
        localStorage.removeItem(name);
    },
    allClear: () => {
        localStorage.clear();
    },
    getAll: () => {
        const items = [];
        for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i);
            const value = localStorage.getItem(key);
            items.push({ key, value });
        }
        return items;
    }
};
