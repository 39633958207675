import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';

import {Avatar, Button} from '@mui/material';

import {stackRouterPush, stackRouterReset} from '../../_helpers/StackRouter';
import {history} from '../../_helpers/history';
import {setMainFeedList, setTobeDeletedFeedItem} from '../../_store/feed.slice';
import axioswrapper from '../../utils/Axios';
import {localStorageUtil} from '../../utils/LocalStorage';
import getUserAgent from '../../utils/UserAgent';
import styles from './ProfileArea.module.scss';

function ProfileArea(props) {
    const {feedDTO, hideBtn, onClickFollowBtn} = props;

    const dispatch = useDispatch();
    const memberId = localStorageUtil.get('memberId');

    const moreBtnClick = (item) => {
        // 삭제하기 클릭시 팝업 메뉴 생성
        dispatch(setTobeDeletedFeedItem(item));

        const ua = getUserAgent();
        if (ua === 'web') {
            if (window.confirm('해당 게시물을 삭제하시겠습니까?')) {
                // 삭제 로직 처리
                const payload = {
                    feedId: feedDTO.id
                };

                axioswrapper
                    .Axios('POST', 'feed/delete', payload)
                    .then((response) => {
                        console.log('피드 삭제 성공!');

                        // 피드 삭제 후 페이지 이동, 데이터 업데이트
                        if (history.location.pathname === '/') {
                            // 메인(홈) 피드 리스트일 때
                            axioswrapper
                                .Axios('GET', `feed/list?memberId=${memberId}&size=50`)
                                .then((response) => {
                                    // console.log('메인 피드 목록 조회 성공', response.data.data);
                                    dispatch(setMainFeedList(response.data.data.lists));
                                })
                                .catch((error) => {
                                    console.log('메인 피드 목록 조회 실패', error);
                                });
                        } else if (history.location.pathname.includes('/feed/detail')) {
                            // 피드 상세일 때
                            dispatch(setMainFeedList([]));
                            stackRouterReset('Home', '/');
                        }
                    })
                    .catch((error) => {
                        console.log('피드 삭제 에러', error);
                    });
            } else {
                console.log('삭제 취소됨');
            }
        } else {
            // 더보기 클릭시 팝업 메뉴 생성
            window.ReactNativeWebView?.postMessage(JSON.stringify({type: 'isRealDeleteFeed'}));
        }
    };

    // 사용자 메인 피드 보기
    const moreUserFeed = () => {
        stackRouterPush('FeedIndex', `/feed/index?memberId=${feedDTO.memberId}`);
    };

    const sideButton = (feedDTO) => {
        //  Button color값
        // 1. 더보기 > lightGrey
        // 2. 팔로워 > lightGreyLightText
        // 3. 팔로우 > primary

        if (feedDTO.isMine) {
            return (
                <Button
                    variant="contained"
                    size="small"
                    className={`MuiButton-sizeSmall-X ${styles.btnMore}`}
                    color="lightGrey"
                    onClick={() => moreBtnClick(feedDTO)}
                >
                    더보기
                </Button>
            );
        } else if (feedDTO.isFollow) {
            return (
                <Button
                    variant="contained"
                    size="small"
                    className={`MuiButton-sizeSmall-X ${styles.btnMore}`}
                    color="lightGreyLightText"
                    onClick={() => onClickFollowBtn(feedDTO.memberId)}
                >
                    팔로워
                </Button>
            );
        } else {
            return (
                <Button
                    variant="contained"
                    size="small"
                    className={`MuiButton-sizeSmall-X ${styles.btnMore}`}
                    color="primary"
                    onClick={() => onClickFollowBtn(feedDTO.memberId)}
                >
                    팔로우
                </Button>
            );
        }
    };

    useEffect(() => {
        sideButton(feedDTO);
    }, [feedDTO]);

    return (
        <div className={styles.userProfileArea}>
            <div className={styles.avatarArea} onClick={moreUserFeed}>
                <Avatar src={feedDTO.profile} />
            </div>
            <div className={styles.userInfoArea}>
                <strong className={styles.userName} onClick={moreUserFeed}>
                    {feedDTO?.nickname}
                </strong>
                <span className={styles.userIntroduce}>{feedDTO.title ? feedDTO.title : feedDTO.intro}</span>
            </div>

            {!hideBtn && sideButton(feedDTO)}
        </div>
    );
}

export default ProfileArea;
