import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {stackRouterReset} from '../../../_helpers/StackRouter';
import {setBeHiddenFeedItem} from '../../../_store/report.slice';
import axioswrapper from '../../../utils/Axios';
import {localStorageUtil} from '../../../utils/LocalStorage';
import getUserAgent from '../../../utils/UserAgent';
import IconButton from '../../Button/IconButton/IconButton';
import ReportDrawer from '../../Drawer/ReportDrawer/ReportDrawer';
import ReportButtonModal from '../../Modal/ReportButtonModal/ReportButtonModal';

function BlockReport(props) {
    const {pinDetailData, whareCalled} = props;
    const [buttonModalOpen, setButtonModalOpen] = useState(false);
    const dispatch = useDispatch();
    const {userFeedList} = useSelector((state) => state.feed);

    const handleActionReport = () => {
        const ua = getUserAgent();
        if (ua === 'web') {
            // 웹일때 웹용 모달 오픈
            setButtonModalOpen(true);
        } else {
            dispatch(setBeHiddenFeedItem(pinDetailData));
            // 앱일때 모바일용 Alert 오픈
            window.ReactNativeWebView?.postMessage(
                JSON.stringify({
                    type: 'actionBlockReport',
                    whareCalled: whareCalled
                })
            );
        }
    };

    const hideBtnClick = (componentName) => {
        if (componentName === 'feedDetail') {
            const payload = {
                feedId: pinDetailData.id,
                memberId: localStorageUtil.get('memberId')
            };

            axioswrapper
                .Axios('POST', 'hide/create', payload)
                .then((response) => {
                    console.log('hide/create ok', response);
                    stackRouterReset('Home', '/');
                })
                .catch((error) => {
                    console.log('hide/create error', error);
                })
                .finally(() => {
                    setButtonModalOpen(false);
                });
        } else if (componentName === 'feedIndex') {
            const payload = {
                banId: userFeedList.memberId,
                memberId: localStorageUtil.get('memberId')
            };

            axioswrapper
                .Axios('POST', 'ban/create', payload)
                .then((response) => {
                    console.log('ban/create ok', response);
                    stackRouterReset('Home', '/');
                })
                .catch((error) => {
                    console.log('ban/create error', error);
                })
                .finally(() => {
                    setButtonModalOpen(false);
                });
        }
    };

    return (
        <>
            <div className="rightBtnArea">
                <IconButton name="dotMenu" width="24" height="24" onClick={handleActionReport} />
            </div>

            {/* 웹용 차단/신고하기 모달 */}
            <ReportButtonModal
                open={buttonModalOpen}
                setOpen={setButtonModalOpen}
                hideBtnClick={hideBtnClick}
                whareCalled={whareCalled}
            />

            {/* 신고사유 입력창 */}
            <ReportDrawer pinDetailData={pinDetailData} />
        </>
    );
}

export default BlockReport;
