import React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import {createTheme} from '@mui/material/styles';
import {circularProgressClasses} from '@mui/material';

function Spinner(props) {
    const {spinnerSize = 50, position = 'absolute'} = props;

    const themeBg = createTheme({
        palette: {
            primary: {
                main: '#f8f8f8'
            }
        }
    });
    const theme = createTheme({
        palette: {
            primary: {
                main: '#297FFF'
            }
        }
    });

    return (
        <Box
            sx={{
                display: 'flex'
            }}
        >
            <CircularProgress
                variant="determinate"
                size={spinnerSize}
                thickness={7}
                value={100}
                theme={themeBg}
            />
            <CircularProgress
                variant="indeterminate"
                size={spinnerSize}
                thickness={7}
                sx={{
                    animationDuration: '1000ms',
                    position: 'absolute',
                    [`& .${circularProgressClasses.circle}`]: {
                        strokeLinecap: 'round'
                    }
                }}
                theme={theme}
            />
        </Box>
    );
}

export default Spinner;
