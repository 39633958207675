import {useState} from 'react';

import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';
import {LoadingButton} from '@mui/lab';
import {Modal} from '@mui/material';
import axioswrapper from '../../utils/Axios';
import {localStorageUtil} from '../../utils/LocalStorage';
import Icon from '../../components/Icon/Icon';
import styles from './CustomPin.module.scss';

function SelectPinModal(props) {
    const {open, onClose, pinImgSrc, pinName, pinId, endAt, getCustomPinListData} = props;

    const [loading, setLoading] = useState(false);
    const [activeButton, setActiveButton] = useState(true);

    // 핀 사용하기 버튼 클릭
    const onClickUsePin = () => {
        setLoading(true); 
        setActiveButton(false);

        const payload = {
            memberId: localStorageUtil.get('memberId'),
            customPinId: pinId
        }

        axioswrapper
        .Axios('POST', 'custom-pin/use', payload)
        .then((response) => {
            console.log('custom-pin/use ok', response);
            setLoading(false);
            setActiveButton(true);
            getCustomPinListData();
            onClose();
        })
        .catch((error) => {
            console.log('custom-pin/use error', error);
            setLoading(false);
            setActiveButton(true);
            onClose();
        });

       
    };

    return (
        <Modal open={open} onClose={onClose} className={styles.customPinModal}>
            <div className={styles.modalWrap}>
                <button type="button" className={styles.btnClose} onClick={onClose}>
                    <Icon name="close" width="24" height="24" fill="#111" />
                </button>
                <div>
                    <div className={styles.selectedPin}>
                        <div className={styles.borderless}>
                            {/* <span>무제한</span> */}
                            <span>{endAt === null ? '무제한' : endAt}</span>
                        </div>
                        <div className={styles.selectedMarker}>
                            <img src={pinImgSrc} />
                        </div>
                        <div className={styles.selectedPinExplan}>
                            <strong className={styles.selectedPinName}>{pinName}</strong>
                        </div>
                    </div>
                    <div className={styles.btnArea}>
                        <LoadingButton
                            variant="contained"
                            fullWidth={true}
                            size="large"
                            loading={loading} // 로딩중 유무
                            loadingPosition="start"
                            startIcon={<LoginOutlinedIcon />}
                            disabled={!activeButton}
                            onClick={onClickUsePin}
                        >
                            핀 사용하기
                        </LoadingButton>
                    </div>
                </div>

                {loading && <div className="preventPageClickArea"></div>}
            </div>
        </Modal>
    );
}

export default SelectPinModal;
