import React, {useEffect, useState} from 'react';
import {useSearchParams} from 'react-router-dom';

import {TabContext, TabList, TabPanel} from '@mui/lab';
import {Tab} from '@mui/material';

import Layout from '../../components/Layout/Layout';
import UserIdList from '../../components/List/UserIdList/UserIdList';
import axioswrapper from '../../utils/Axios';
import {localStorageUtil} from '../../utils/LocalStorage';

function Follow(props) {
    const [serchParams] = useSearchParams();

    const followState = serchParams.get('followState');
    const memberId = serchParams.get('memId');
    const nickname = serchParams.get('nickname');
    const myId = localStorageUtil.get('memberId');
    const isMine = memberId === myId;

    const [value, setValue] = useState(followState);
    const [follower, setFollower] = useState([]);
    const [following, setFollowing] = useState([]);

    // 팔로워 목록 조회
    const getFollowerList = () => {
        axioswrapper
            .Axios(
                'GET',
                `follow/follower-list?followerId=${memberId}&myId=${localStorageUtil.get('memberId')}`
            )
            .then((response) => {
                // console.log('팔로워 목록 조회 성공', response.data.data);
                setFollower(response.data.data);
            })
            .catch((error) => {
                console.log('팔로워 목록 조회 실패', error);
            });
    };

    // 팔로잉 목록 조회
    const getFollowingList = () => {
        axioswrapper
            .Axios(
                'GET',
                `follow/following-list?followingId=${memberId}&myId=${localStorageUtil.get('memberId')}`
            )
            .then((response) => {
                // console.log('팔로잉 목록 조회 성공', response.data.data);
                setFollowing(response.data.data);
            })
            .catch((error) => {
                console.log('팔로잉 목록 조회 실패', error);
            });
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);

        if (newValue === 'follower') {
            getFollowerList();
        } else if (newValue === 'following') {
            getFollowingList();
        }
    };

    useEffect(() => {
        getFollowerList();
        getFollowingList();
    }, []);

    return (
        <Layout pageTitle={nickname} back={true} activeNav={isMine ? 'myPage' : null}>
            <div className="followContentsArea">
                <TabContext value={value}>
                    <div>
                        <TabList
                            variant="fullWidth"
                            textColor="secondary"
                            indicatorColor="secondary"
                            onChange={handleChange}
                        >
                            <Tab label="팔로워" value="follower" />
                            <Tab label="팔로잉" value="following" />
                        </TabList>
                    </div>
                    <TabPanel value="follower">
                        <div className="totalCountArea">
                            모든 팔로워 <strong>{follower.totalCount}</strong>
                        </div>
                        <UserIdList feedDTO={follower.lists} />
                    </TabPanel>
                    <TabPanel value="following">
                        <div className="totalCountArea">
                            모든 팔로잉 <strong>{following.totalCount}</strong>
                        </div>
                        <UserIdList feedDTO={following.lists} />
                    </TabPanel>
                </TabContext>
            </div>
        </Layout>
    );
}

export default Follow;
