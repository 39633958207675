import React from 'react';
import {useDispatch} from 'react-redux';

import {stackRouterBack, stackRouterPush} from '../../../_helpers/StackRouter';
import {setAppNotiSave} from '../../../_store/etc.slice';
import Icon from '../../Icon/Icon';
import styles from './Header.module.scss';

function Header(props) {
    const {main, pageTitle, back} = props;

    const dispatch = useDispatch();

    // 알림 버튼 클릭
    const handleNotiClick = () => {
        stackRouterPush('Notification', '/notification');
    };

    // back 버튼 클릭
    const handleBackBtnClick = () => {
        if (pageTitle === '알림') {
            dispatch(setAppNotiSave(true));
        }

        stackRouterBack();
    };

    return (
        <header id={styles.header}>
            <div className={styles.headerWrap}>
                {back && (
                    <button type="button" className={styles.btnBack} onClick={handleBackBtnClick}>
                        <Icon name="back" width="24" height="24" />
                    </button>
                )}

                <h1 className={main ? styles.logo : ''}>{main ? 'wepin' : pageTitle}</h1>

                {main && (
                    <button type="button" className={styles.btnNoti} onClick={handleNotiClick}>
                        <Icon name="alarm" width="24" height="24" />
                        {/* <i className={styles.notiIcon}></i> {} */}
                    </button>
                )}
            </div>
        </header>
    );
}

export default Header;
