import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useSearchParams} from 'react-router-dom';

import CreateIcon from '@mui/icons-material/Create';
import {Avatar, Button, IconButton} from '@mui/material';

import {stackRouterPush} from '../../_helpers/StackRouter';
import {resetUserFeedList, setUserFeedList} from '../../_store/feed.slice';
import FeedDeatilLayout from '../../components/Layout/FeedDeatilLayout';
import FeedList from '../../components/List/FeedList/FeedList';
import axioswrapper from '../../utils/Axios';
import {localStorageUtil} from '../../utils/LocalStorage';
import styles from './FeedIndex.module.scss';

function FeedIndex(props) {
    const [myIsFollow, setMyIsFollow] = useState(false);
    const [followerCnt, setFollowerCnt] = useState(0);
    const [followingCnt, setFollowingCnt] = useState(0);

    const dispatch = useDispatch();
    const [serchParams] = useSearchParams();
    const memberId = serchParams.get('memberId');
    const pageType = serchParams.get('pageType');

    const {userFeedList} = useSelector((state) => state.feed);
    const myId = localStorageUtil.get('memberId');

    // 내 피드인지 아닌지 체크
    const isMine = memberId === myId;

    // 프로필 편집
    const goToProfileModify = () => {
        stackRouterPush('Setting', '/mypage/profile/modify');
    };

    // 팔로우, 팔로우 취소
    const isFollowBtnClick = () => {
        const followUrl = !myIsFollow ? 'follow/create' : 'follow/delete';

        // wer : 내가 팔로우 하려는 사람, ing이 나
        const payload = {
            followerId: memberId,
            followingId: localStorageUtil.get('memberId')
        };

        axioswrapper
            .Axios('POST', followUrl, payload)
            .then((response) => {
                console.log(`${followUrl} ok`, response);
                setMyIsFollow(!myIsFollow);
                setFollowerCnt(response.data.data.followerCnt);
                setFollowingCnt(response.data.data.followingCnt);
            })
            .catch((error) => {
                console.log(`${followUrl} error`, error);
            });
    };

    // 팔로워, 팔로잉 클릭
    const onClickFollow = (menuLink) => {
        const stateData = {
            memId: memberId,
            followState: menuLink,
            nickname: userFeedList.nickname
        };

        stackRouterPush(
            'Follow',
            `/follow/${memberId}?memId=${memberId}&followState=${menuLink}&nickname=${userFeedList.nickname}`
        );
    };

    useEffect(() => {
        // 사용자 피드 전환시 이전 사용자 피드 내용이 있다면 비움
        if (Object.keys(userFeedList).length > 1) {
            dispatch(resetUserFeedList());
        }

        axioswrapper
            .Axios('GET', `member/user-main?memberId=${memberId}&myId=${myId}`)
            .then((response) => {
                // console.log('유저 메인 피드 조회 성공', response.data);
                dispatch(setUserFeedList(response.data.data));

                setMyIsFollow(response.data.data.isFollow);
                setFollowerCnt(response.data.data.followerCnt);
                setFollowingCnt(response.data.data.followingCnt);
            })
            .catch((error) => {
                console.log('유저 메인 피드 조회 실패', error);
            });
    }, [memberId, myId]);

    return (
        <FeedDeatilLayout
            pageTitle={userFeedList?.nickname}
            isMine={isMine}
            back={pageType !== 'myPage'}
            whareCalled="feedIndex"
        >
            <div className="innerWrap">
                <div className={styles.feedInfo}>
                    {/* 사용자 정보 */}
                    <div className={styles.userProfile}>
                        <div className={styles.profileImgArea}>
                            <Avatar src={userFeedList?.profile} />

                            {isMine && (
                                <>
                                    {/* 프로필 편집 버튼 */}
                                    <IconButton
                                        aria-label="modify profile"
                                        color="white"
                                        className={styles.btnModifyProfile}
                                        onClick={goToProfileModify}
                                    >
                                        <CreateIcon />
                                    </IconButton>
                                </>
                            )}
                        </div>
                        <div className={styles.userInfoArea}>
                            <strong className={styles.name}>{userFeedList?.name}</strong>
                            {userFeedList?.intro && <p className={styles.intro}>{userFeedList?.intro}</p>}
                        </div>
                    </div>

                    <div className={styles.feedCountArea}>
                        <dl>
                            <dt>내 핀</dt>
                            <dd>{userFeedList?.feedCnt}</dd>
                        </dl>
                        <dl className={styles.cusor} onClick={() => onClickFollow('follower')}>
                            <dt>팔로워</dt>
                            <dd>{followerCnt}</dd>
                        </dl>
                        <dl className={styles.cusor} onClick={() => onClickFollow('following')}>
                            <dt>팔로잉</dt>
                            <dd>{followingCnt}</dd>
                        </dl>
                    </div>

                    <div className={styles.btnArea}>
                        {isMine ? (
                            <Button
                                variant="contained"
                                size="medium"
                                color="lightGrey"
                                onClick={goToProfileModify}
                            >
                                프로필 편집
                            </Button>
                        ) : myIsFollow ? (
                            <Button
                                variant="contained"
                                size="medium"
                                color="lightGreyLightText"
                                onClick={isFollowBtnClick}
                            >
                                팔로우 취소
                            </Button>
                        ) : (
                            <Button
                                variant="contained"
                                size="medium"
                                color="primary"
                                onClick={isFollowBtnClick}
                            >
                                팔로우
                            </Button>
                        )}
                    </div>
                </div>

                {/* 피드 리스트 */}
                <FeedList feedList={userFeedList?.feedList} myIsFollow={myIsFollow} />
            </div>
        </FeedDeatilLayout>
    );
}

export default FeedIndex;
