import React, {useState} from 'react';

import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';
import {LoadingButton} from '@mui/lab';
import {FormControl, FormControlLabel, Modal, Radio, RadioGroup} from '@mui/material';

import {stackRouterPush} from '../../../_helpers/StackRouter';
import axioswrapper from '../../../utils/Axios';
import Icon from '../../Icon/Icon';
import styles from './ReportDetailModal.module.scss';

function ReportDetailModal(props) {
    const {data, tabValue, open, setOpen, getRequestList, getBlackList} = props;

    const [loading, setLoading] = useState(false);
    const [balckStatus, BalckStatus] = useState('CANCEL');

    // 모달 닫기
    const modalClose = () => {
        setOpen(false);
    };

    // 신고 게시물 상세 보기
    const onClickFeedDetail = () => {
        const feedId = data.feedId;

        stackRouterPush('FeedDetail', `/feed/detail?feedId=${feedId}`);
    };

    // 라디오 버튼 상태 변경
    const handleRadioChange = (event) => {
        BalckStatus(event.target.value);
    };

    // 차단 여부 상태 변경
    const updateBlackStatus = () => {
        const payload = {
            id: data.id,
            status: balckStatus
        };

        setLoading(true);

        axioswrapper
            .Axios('POST', 'black/update', payload)
            .then((response) => {
                // console.log('black/update ok', response);
                setLoading(false);
                modalClose();

                // 리스트 데이터 업데이트 (다시 불러오기)
                if (tabValue === 'REQUEST') {
                    getRequestList();
                } else {
                    getBlackList();
                }
            })
            .catch((error) => {
                console.log('black/update error', error);
                setLoading(false);
            });
    };

    return (
        <Modal open={open} id={styles.reportDetailModal} className="fullPageModal">
            <div className="modalConWrap">
                <div className="modalTitleArea">
                    신고 내용
                    <button type="button" className="btnClose" onClick={modalClose}>
                        <Icon name="close_b" width="24" height="24" fill="#111" />
                    </button>
                </div>
                <div className="modalContents">
                    <section>
                        <h2>접수 내용</h2>
                        <dl>
                            <dt>불량 사용자 ID</dt>
                            <dd>{data.blackNick}</dd>
                        </dl>
                        <dl>
                            <dt>신고한 사용자 ID</dt>
                            <dd>{data.memberNick}</dd>
                        </dl>
                        <dl>
                            <dt>접수 시간</dt>
                            <dd>{data.createdAt}</dd>
                        </dl>
                        {data.feedId && (
                            <dl>
                                <dt>신고 게시물</dt>
                                <dd>
                                    <button
                                        type="button"
                                        onClick={() => onClickFeedDetail()}
                                        className={styles.linkButton}
                                    >
                                        {data.feedTitle}
                                    </button>
                                </dd>
                            </dl>
                        )}
                        <dl>
                            <dt>신고 사유</dt>
                            <dd>{data.reason}</dd>
                        </dl>
                        <dl>
                            <dt>신고 상세 내용</dt>
                            <dd>{data.detail ? data.detail : '-'}</dd>
                        </dl>
                    </section>
                    <section>
                        <h2>차단 여부</h2>
                        <FormControl className={styles.radioGroup}>
                            <RadioGroup
                                name="block-status-group"
                                defaultValue="CANCEL"
                                onChange={handleRadioChange}
                            >
                                <FormControlLabel
                                    value="CANCEL"
                                    control={<Radio />}
                                    label="아니요, 이상 없음"
                                />
                                <FormControlLabel value="BLACK" control={<Radio />} label="예, 차단" />
                            </RadioGroup>
                        </FormControl>
                    </section>
                </div>
                <div className="modalBtnArea">
                    <LoadingButton
                        variant="contained"
                        fullWidth={true}
                        size="large"
                        loading={loading} // 로딩중 유무
                        loadingPosition="start"
                        startIcon={<LoginOutlinedIcon />}
                        disabled={loading}
                        onClick={updateBlackStatus}
                    >
                        {tabValue === 'REQUEST' ? '완료' : '수정'}
                    </LoadingButton>
                </div>

                {loading && <div className="preventPageClickArea"></div>}
            </div>
        </Modal>
    );
}

export default ReportDetailModal;
